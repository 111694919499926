import { createContext, Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import TopicRecognition from "./TopicRecognition";
import "./TopicRecognition.scss";
import { initFilter } from "./models/TopicRecognitionModel";

export interface TopicRecognitionContextProps {
  topicRecognitionList: any;
  setTopicRecognitionList: Dispatch<SetStateAction<any>>;
  onReset: () => void;
  proposalInfo: any;
  setProposalInfo: Dispatch<SetStateAction<any>>;
  onResetProposal: () => void;
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
}

const initialContext = {
  topicRecognitionList: {},
  setTopicRecognitionList: () => {},
  onReset: () => {},
  proposalInfo: {},
  setProposalInfo: () => {},
  onResetProposal: () => {},
  filter: {},
  setFilter: () => {},
};

export const TopicRecognitionContext =
  createContext<TopicRecognitionContextProps>(initialContext);

export const TopicRecognitionProvider: FC = () => {
  const [topicRecognitionList, setTopicRecognitionList] = useState<any>({});
  const [proposalInfo, setProposalInfo] = useState<any>({});
  const [filter, setFilter] = useState<any>(initFilter);
  const value = useMemo(
    () => ({
      topicRecognitionList,
      setTopicRecognitionList,
      onReset: () => {
        setTopicRecognitionList({});
      },
      proposalInfo,
      setProposalInfo,
      filter,
      setFilter,
      onResetProposal: () => {
        setTopicRecognitionList({});
      },
    }), [topicRecognitionList, proposalInfo, filter]);
  return (
    <TopicRecognitionContext.Provider
      value={value}
    >
      <div className="bg-white h-100">
        <div className="flex-1 flex flex-column">
          <div className="py-1">
            <TopicRecognition />
          </div>
        </div>
      </div>
    </TopicRecognitionContext.Provider>
  );
};

export default TopicRecognitionProvider;
