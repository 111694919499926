import axios from "axios";
import { TBatchOfRegistrationFilter } from "../models/TopicRegistrationModel";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { localStorageItem } from "../../utils/LocalStorage";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];
const API_XADMIN = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"];

export const searchBatchOfRegistration = (data: TBatchOfRegistrationFilter) => {
  let url = `${API_PATH}/regis-periods/search`;
  delete data.yearOption;
  delete data.statusOption;
  return axios.get(url, { params: data });
};
export const registerTopic = (data: any) => {
  let url = `${API_PATH}/topics/register`;
  return axios.post(url, data);
};

export const registerTopicAgain = (data: any, id: string) => {
  let url = `${API_PATH}/topics/${id}/re-register`;
  return axios.post(url, data);
};

export const addProposal = (data: any) => {
  let url = `${API_PATH}/topics/propose`;
  return axios.post(url, data);
};

export const getTopicById = (id: string) => {
  let url = `${API_PATH}/topics/${id}`;
  return axios.get(url);
}

export const searchDepartment = (data: any) => {
  let url = `${API_XADMIN}/departments/search`;
  return axios.get(url, { params: data });
};