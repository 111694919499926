import axios from "axios";
import { TTopicFilter } from "../models/TopicModel";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];
const API_PATH_EMPLOYEE = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["hrmApiUrl"] ?? process.env.REACT_APP_EMPLOYEE_API_URL;

export const searchTopic = (data: TTopicFilter) => {
  let url = `${API_PATH}/topics/search`;
  delete data.yearOption;
  return axios.get(url, { params: data });
};

export const getTopicById = (id: string) => {
  let url = `${API_PATH}/topics/${id}`;
  return axios.get(url);
}

export const approveProposal = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/endorse-proposal`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const approveOutline = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/consent-outline`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const approveTopic = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/approve`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const approveAcceptance = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/accept`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const rejectOutline = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/repudiate-outline`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const rejectTopic = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/disapprove`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const rejectProposal = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/deny-proposal`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const rejectAcceptance = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/reject`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const addReportAttachment = (data: any) => {
  let url = `${API_PATH}/topics/paper`;
  return axios.post(url, data);
};

export const addExtend = (data: any) => {
  let url = `${API_PATH}/topics/extend-deadline`;
  return axios.post(url, data);
};

export const addFeedback = (data: any) => {
  let url = `${API_PATH}/topics/suggest`;
  return axios.post(url, data);
};

export const addRequestEdit = (data: any) => {
  let url = `${API_PATH}/topics/request-modification`;
  return axios.post(url, data);
};

export const getAttachmentProduct = (data: { topicId: string }) => {
  let url = `${API_PATH}/topics/report`;
  return axios.get(url, { params: data });
};

export const addRequestEditAcceptance = (data: any) => {
  let url = `${API_PATH}/topics/request-report-modification`;
  return axios.post(url, data);
};
export const  regisScienceMinute = (id:string ,data: any) => {
  let url = `${API_PATH}/topics/${id}/science-record`;
  return axios.post(url, data);
};

export const  regisAcceptanceMinute = (id:string ,data: any) => {
  let url = `${API_PATH}/topics/${id}/acceptance-record`;
  return axios.post(url, data);
};

export const addReviewScienceCouncil = (data: any) => {
  let url = `${API_PATH}/topic-review/science`;
  return axios.post(url, data);
};

export const addReviewAcceptanceCouncil = (data: any) => {
  let url = `${API_PATH}/topic-review/acceptance`;
  return axios.post(url, data);
};

export const getCommittees = (committeeId: string) => {
  let url = `${API_PATH}/committees/${committeeId}/info`;
  return axios.get(url);
};

export const getUserDetails = (data: any) => {
  let url = `${API_PATH_EMPLOYEE}/users/page`;
  return axios.get(url, { params: data });
}

export const signScienceEvaluation = (id: any) => {
  let url = `${API_PATH}/topic-review/science/${id}/sign`;
  return axios.post(url);
};

export const signScienceAcceptance = (id: any) => {
  let url = `${API_PATH}/topic-review/acceptance/${id}/sign`;
  return axios.post(url);
};