import axios from "axios";
import { TBatchOfRegistrationFilter } from "../../../dang-ky-de-tai/models/TopicRegistrationModel";
import { TMyTopicFilter } from "../../../de-tai-cua-toi/models/MyTopicModel";
import { TAcceptanceCouncilFilter } from "../models/AcceptanceCouncilManagerModels";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];

export const searchAcceptanceCouncil = (data: TAcceptanceCouncilFilter) => {
  let url = `${API_PATH}/committees/search`;
  delete data.yearOption;
  return axios.get(url, { params: data });
};

export const addAcceptanceCouncil = (data: any) => {
  let url = `${API_PATH}/committees`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const updateAcceptanceCouncil = (data: any, id: string) => {
  let url = `${API_PATH}/committees/${id}`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.put(url, data, config);
};

export const searchBatchOfRegistration = (data: TBatchOfRegistrationFilter) => {
  let url = `${API_PATH}/regis-periods/search`;
  return axios.get(url, { params: data });
};

export const getSummaryInfo = (data: TAcceptanceCouncilFilter) => {
  let url = `${API_PATH}/topics/inspection-info`;
  return axios.get(url, { params: data });
};

export const deleteAcceptanceCouncilById = (id: string) => {
  let url = `${API_PATH}/committees/${id}`;
  return axios.delete(url);
};

export const getAcceptanceCouncilById = (id: string) => {
  let url = `${API_PATH}/committees/${id}`;
  return axios.get(url);
};

export const searchTopic = (data: TMyTopicFilter) => {
  let url = `${API_PATH}/topics/pending-inspection`;
  delete data.yearOption;
  return axios.get(url, { params: data });
};