import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { ConfirmDialog } from "../../../component/ConfirmDialog";
import { CODE } from "../../../utils/Constant";
import { TABS } from "../../constants/constants";
import { TMainResearcher } from "../../models/MyTopicModel";
import { chairmanSignAcceptance, getTopicAcceptanceMinutes, secretarySignAcceptance } from "../../services/services";

type IProps = {
  currentTab: string;
  topicId?: string;
  mainResearcher: TMainResearcher;
  coMainResearcher: TMainResearcher;
};
const TabAcceptanceMinutes: FC<IProps> = (props) => {
  const { currentTab, topicId, mainResearcher, coMainResearcher } = props;
  const { setIsLoading } = useContext(AppContext);

  const [minutesInfo, setMinutesInfo] = useState<any>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [signer, setSigner] = useState<string>("");

  useEffect(() => {
    if (!currentTab || !topicId) return;
    getMinutesInfo(topicId);
  }, [currentTab, topicId]);

  const getMinutesInfo = async (topicId: string) => {
    if (currentTab !== TABS.ACCEPTANCE_MINUTES.eventKey) return;
    try {
      setIsLoading(true);
      let { data } = await getTopicAcceptanceMinutes(topicId);
      if (data?.code === CODE.SUCCESS) {
        setMinutesInfo(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleYesClick = async () => {
    try {
      if (!signer || !minutesInfo?.id) return;
      let api = null;
      switch (signer) {
        case "secretary":
          api = secretarySignAcceptance(minutesInfo?.id);
          break;
        case "chairman":
          api = chairmanSignAcceptance(minutesInfo?.id);
          break;
        default:
          break;
      }
      if (!api) return;
      let { data } = await api;
      if (data?.code === CODE.SUCCESS) {
        setOpenConfirmDialog(false);
        toast.success(`Ký biên bản thành công!`);
        getMinutesInfo(String(topicId));
      } else {
        toast.error(`Ký biên bản thất bại`);
        setOpenConfirmDialog(false);
      }
    } catch (error) {
      console.error(error);
      setOpenConfirmDialog(false);
    }
  };

  return (
    <Modal.Body className="spaces p-0 bg-white">
      <div className="a4-page">
        <div className="a4-container">
          <div className="header-container">
            <div className="left-column center-text">
              <h3 className="bold">SỞ Y TẾ HÀ NỘI</h3>
              <h3 className="bold underline">BỆNH VIỆN ĐA KHOA SÓC SƠN</h3>
            </div>
            <div className="right-column center-text">
              <p className="bold m-0">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
              <p className="underline bold spaces mb-8">
                Độc lập - Tự do - Hạnh phúc
              </p>
            </div>
          </div>

          <h3 className="text-center bold section fs-3">
            BIÊN BẢN CUỘC HỌP HỘI ĐỒNG NGHIỆM THU
          </h3>
          <h4 className="text-center bold fs-3">
            SÁNG KIẾN, GIẢI PHÁP CÔNG TÁC CẤP CƠ SỞ
          </h4>

          <div className="section">
            <p>
              <b>1. Tên sáng kiến: </b>
              {minutesInfo?.topicName}
            </p>
            <div className="pb-3">
              <p className="m-0">
                <b>2. Chủ nhiệm sáng kiến, giải pháp: </b>
                {mainResearcher?.name}
              </p>
              {coMainResearcher && (
                <p style={{ paddingLeft: "16px", margin: "0" }}>
                  Đồng chủ nhiệm: {coMainResearcher?.name} và cộng sự
                </p>
              )}
            </div>
            <p>
              <b>3. Đơn vị thực hiện sáng kiến, giải pháp:</b> Bệnh viện đa
              khoa Sóc Sơn
            </p>
            <p>
              <b>4. Ngày họp:</b>&nbsp;
              {minutesInfo?.date
                ? moment(minutesInfo?.date).format("DD/MM/YYYY")
                : ""}
            </p>
            <p>
              <b>5. Địa điểm:</b>&nbsp;
              {minutesInfo?.place ?? ""}
            </p>

            <p>
              <b>6. Thành viên hội đồng:</b> Theo QĐ số {minutesInfo?.code}
              /QĐ-BVĐKSS của Bệnh viện đa khoa Sóc Sơn về việc nghiệm thu sáng
              kiến cải tiến năm 2024
            </p>
            <p>
              Tổng số: 5. Có mặt: {minutesInfo?.numAttendees}. Vắng mặt:{" "}
              {minutesInfo?.numAbsent}
            </p>
            <p>
              <b>7. Khách mời tham dự: &nbsp;</b> {minutesInfo?.guest}
            </p>
            <p>
              <b>8. Nội dung chấm điểm:</b>
            </p>
            <table className="mark-table">
              <thead>
                <tr>
                  <td width={"50px"}>STT</td>
                  <td>Tiêu chí</td>
                  <td>Điểm chuẩn</td>
                  <td width={"100px"}>
                    <p className="m-0">Điểm đạt</p>
                    <p className="m-0">(trung bình)</p>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Tính khoa học</td>
                  <td>20</td>
                  <td>{minutesInfo?.scoreScientificNature}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Tính mới, tính sáng tạo</td>
                  <td>20</td>
                  <td>{minutesInfo?.scoreCreativity}</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Tính hiệu quả</td>
                  <td>30</td>
                  <td>{minutesInfo?.scoreEffectiveness}</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Tính ứng dụng, khả thi</td>
                  <td>30</td>
                  <td>{minutesInfo?.scoreApplicability}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Tổng số</td>
                  <td>100</td>
                  <td>
                    {(Number(minutesInfo?.scoreScientificNature) || 0) +
                      (Number(minutesInfo?.scoreCreativity) || 0) +
                      (Number(minutesInfo?.scoreEffectiveness) || 0) +
                      (Number(minutesInfo?.scoreApplicability) || 0)}
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Tổng số điểm: 100. Điểm trung bình:{" "}
              {(Number(minutesInfo?.scoreScientificNature) || 0) +
                (Number(minutesInfo?.scoreCreativity) || 0) +
                (Number(minutesInfo?.scoreEffectiveness) || 0) +
                (Number(minutesInfo?.scoreApplicability) || 0)}
            </p>
            <p>Xếp loại phê duyệt:</p>
            <p>
              <b>9. Kết luận và kiến nghị của Hội đồng:</b>{" "}
              {minutesInfo?.committeeRecommendation}
            </p>
          </div>
          <div className="signature-container">
            <div className="left-signature">
              <p>
                <b>THƯ KÝ</b>
              </p>
              <p className="pt-4">{minutesInfo?.secretaryName}</p>
              <i>{minutesInfo?.isSecretarySigned ? "(Đã ký)" : ""}</i>
              {minutesInfo?.allowSecretarySign && (
                <p className="pt-4">
                  <Button
                    variant="primary"
                    className="min-w-75px fw-bold"
                    type="button"
                    onClick={() => {
                      setOpenConfirmDialog(true);
                      setSigner("secretary");
                    }}
                  >
                    {"Ký"}
                  </Button>
                </p>
              )}
            </div>

            <div className="right-signature">
              <p>
                <b>CHỦ TỊCH HỘI ĐỒNG</b>
              </p>
              <p className="pt-4">{minutesInfo?.chairmanName}</p>
              <i>{minutesInfo?.isChairmanSigned ? "(Đã ký)" : ""}</i>
              {minutesInfo?.allowChairmanSign && (
                <p className="pt-4">
                  <Button
                    variant="primary"
                    className="min-w-75px fw-bold"
                    type="button"
                    onClick={() => {
                      setOpenConfirmDialog(true);
                      setSigner("chairman");
                    }}
                  >
                    {"Ký"}
                  </Button>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {openConfirmDialog && (
        <ConfirmDialog
          show={openConfirmDialog}
          yes="Xác nhận"
          onYesClick={() => handleYesClick()}
          message={`Bạn có chắc muốn ký biên bản này?`}
          close="Huỷ"
          onCloseClick={() => setOpenConfirmDialog(false)}
          title="Xác nhận thao tác"
        />
      )}
    </Modal.Body>
  );
};
export default TabAcceptanceMinutes;
