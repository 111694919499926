import { columnNamesType, OctTable, OctTablePagination } from "@oceantech/oceantech-ui";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "../appContext/AppContext";
import { TTopicDetails } from "../quan-ly-de-tai/de-tai/models/TopicModel";
import { CODE, DEFAULT_PAGE_INDEX } from "../utils/Constant";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../utils/PageUtils";
import AddProposalDialog from "./components/AddProposalDialog";
import AddTopicRegistrationDialog from "./components/AddTopicRegistrationDialog";
import Filter from "./components/Filter";
import { STATUS_ID } from "./constants/constants";
import { TBatchOfRegistration } from "./models/TopicRegistrationModel";
import { searchBatchOfRegistration } from "./services/services";
import { TopicRegistrationContext } from "./TopicRegistrationContext";

export const OpenTopicRegistration: FC = () => {

  const { setIsLoading } = useContext(AppContext);
  const { filter, setFilter, batchOfRegistrationList, setBatchOfRegistrationList } = useContext(TopicRegistrationContext);

  const [openDialogAdd, setOpenDialogAdd] = useState<boolean>(false);
  const [openProposalDialogAdd, setOpenProposalDialogAdd] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState<TBatchOfRegistration>({});
  const [totalElements, setTotalElements] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [topicInfo, setTopicInfo] = useState<TTopicDetails>({});
 
  useEffect(() => {
    getBatchOfRegistrationList();
  }, [filter])

  useEffect(() => {
    setFilter({...filter, pageIndex: page, pageSize: rowsPerPage})
  }, [page, rowsPerPage])

  const getBatchOfRegistrationList = async () => {
    try {
      setIsLoading(true);
      let { data } = await searchBatchOfRegistration(filter);
      if (data?.code === CODE.SUCCESS) {
        setOpenDialogAdd(false);
        setBatchOfRegistrationList(data?.data?.content);
        setTotalElements(data?.data?.pageable?.totalElements);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
      },
      cellStyle: {
        textAlign: "center",
        color: "#333",
      },
      render: (row, index, stt) => {
        return <div>{(((Number(filter?.pageIndex) - 1) * Number(filter?.pageSize) + index) + 1)}</div>
      },
    },
    {
      name: "Đợt đăng ký",
      field: "startDate",
      headerStyle: {
        minWidth: "50px",
      },
      cellStyle: {
        textAlign: "center",
        color: "#333",
        width: "100px",
      },
      render: (row, index, stt) => {
        return (
          <div>
            {row?.startDate && moment(row?.startDate).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        width: "120px",
      },
      cellStyle: {
        textAlign: "center",
        width: "120px",
      },
      render: (row, index, stt) => {
        return (
          <div
            className={`status ${row?.statusName === "Mở" ? "open" : "close"}`}
          >
            {row?.statusName}
          </div>
        );
      },
    },
    {
      name: "Tên đợt",
      field: "name",
      headerStyle: {
        minWidth: "300px",
      },
      cellStyle: {
        color: "#333",
      }
    },
    {
      name: "Thao tác",
      field: "action",
      headerStyle: {
        width: "120px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return (
          <div className="flex justify-content-center align-items-center gap-4 px-4">
            <div
              className={`regis-topic-action ${(row?.proposalCode || row?.topicIdOfCurrentUser) ? "text-gray disable" : "text-pri"} ${
                row?.statusId === STATUS_ID.CLOSE ? "text-gray disable" : ""
              }`}
              onClick={() => {
                if (row?.statusId === STATUS_ID.CLOSE) {
                  toast.warning("Phải mở đợt đăng ký trước");
                  return;
                }
                setOpenProposalDialogAdd(true);
                setDataEdit(row);
              }}
            >
              <span
                  className="tooltips"
                  data-tooltip="Tạo đề xuất"
                  data-tooltip-pos="left"
                  data-tooltip-length="medium"
                >
                  <i
                    className={`bi bi-send-plus fs-2 ${(row?.numTopicsOfCurrentUser === 2) ? "text-gray disable" : "text-pri"} ${
                      row?.statusId === STATUS_ID.CLOSE
                        ? "text-gray disable"
                        : ""
                    }`}
                  ></i>
                </span>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white">
      <div className="spaces bg-gray radius-2">
        <div className="bg-white spaces width-100 flex justify-content-between p-10 border-bottom">
          <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0">
            Danh sách đợt đăng ký
          </h4>
        </div>
        <Filter/>
        <div className="d-flex flex-column spaces width-100">
          <div className="bg-white">
            <div className=" spaces bg-white m-0">
              <div className="bg-white spaces mb-6 pb-15 fl-1 ml-10 mr-10">
                <OctTable
                  id="ds-dot-dang-ky"
                  className="spaces dot-dang-ky-table"
                  columns={columns}
                  data={batchOfRegistrationList}
                  justFilter={false}
                  clearToolbar
                  noToolbar
                  noPagination
                />
                <OctTablePagination
                  handlePagesChange={handlePagesChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalPages={Math.ceil(totalElements/rowsPerPage)}
                  totalElements={totalElements}
                  rowsForPage={rowsForPage}
                  numberOfElements={0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openDialogAdd && (
        <AddTopicRegistrationDialog
          show={openDialogAdd}
          onHide={() => {
            setTopicInfo({});
            setOpenDialogAdd(false);
          }}
          batchOfRegistrationInfo={dataEdit}
          onRefresh={getBatchOfRegistrationList}
          topicInfo={topicInfo}
        />
      )}
      {openProposalDialogAdd && (
        <AddProposalDialog
          show={openProposalDialogAdd}
          onHide={() => setOpenProposalDialogAdd(false)}
          batchOfRegistrationInfo={dataEdit}
          onRefresh={getBatchOfRegistrationList}
        />
      )}
    </div>
  );
};

export default OpenTopicRegistration;
