import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROLE } from '../../modules/utils/Constant';
import { hasAuthority } from '../../modules/utils/Permission';
import { listModule } from './constant';
import './homepage.scss';

export function HomePage() {
  const navigate = useNavigate();
  const handleButtonClick = (to: string) => {
    navigate(to)
  };

  useEffect(() => {
    let defaultHomePage = '';
    if (hasAuthority(ROLE.GIAM_DOC)) {
      defaultHomePage = '/quan-ly-de-tai/de-tai';
    } else if (hasAuthority(ROLE.PHONG_NCKH)) {
      defaultHomePage = '/quan-ly-de-tai/mo-dot-dang-ky';
    } else {
      defaultHomePage = '/de-tai-cua-toi';
    }
    navigate(defaultHomePage, { replace: true });
  }, [])

  return (
    <div className='main'>
      <div className='container'>
        <div>
          <p className='text-uppercase text-center text-white fw-normal heading-3 mb-15'>Phần mềm quản lý nghiên cứu khoa học</p>
        </div>
        <div className='button-group gap-10 align-items-start'>
          {listModule.map((item: any, index: number) => (
            <div className='item-button' key={index + ""}>
              <button
                className='btn btn-light-primary fw-bolder btn-homepage'
                onClick={() => handleButtonClick(item?.to)}>
                <img src={item?.imgSrc} alt={item?.name}></img>
              </button>
              <span className='title-button'>{item?.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}