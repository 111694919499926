import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { CODE, MEMBER_TYPE } from "../../../utils/Constant";
import { getCommittees, getTopicById, regisScienceMinute } from "../services/services";
import { TDataScienceMinutes, TMember } from "../models/TopicModel";
import { ConfirmDialog } from "../../../component/ConfirmDialog";
import { chairmanSignScience, secretarySignScience } from "../../../de-tai-cua-toi/services/services";

interface Props {
  show: boolean;
  onHide: () => void;
  id: string;
  onRefresh: () => Promise<void>;
}

const generalTextareaStyle: any = {
  fontFamily: "Times New Roman",
  fontSize: "16px",
  display: "block",
  zIndex: "1",
  width: "100%",
  outline: "unset",
  position: "relative",
  resize: "none",
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
  height: "auto",
  minHeight: "24px",
  backgroundColor: "transparent",
};

const ScienceMinutesDialog = (props: Props) => {
  const { show, onHide, id, onRefresh } = props;
  const { setIsLoading } = useContext(AppContext);
  const [dataMinutes, setDataMinutes] = React.useState<TDataScienceMinutes>({
    allowUpdating: true,
  });
  const [topicDetails, setTopicDetails] = React.useState<any>({});
  const [numRows, setNumRows] = React.useState<any>({});
  const [scienceCommitteeId, setScienceCommitteeId] = React.useState<string>("");
  const [committeeInfo, setCommitteeInfo] = React.useState<any>({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [signer, setSigner] = useState<string>("");

  const [validatorInput, setValidatorInput] = React.useState<any>({
    place: {
      valid: false,
      name: "place",
    },
    dayDate: {
      valid: false,
      name: "dayDate",
    },
    monthDate: {
      valid: false,
      name: "monthDate",
    },
    yearDate: {
      valid: false,
      name: "yearDate",
    },
    supervisor: {
      valid: false,
      name: "supervisor",
    },
    numAttendees: {
      valid: false,
      name: "numAttendees",
    },
    presenter: {
      valid: false,
      name: "presenter",
    },
    presenterDuty: {
      valid: false,
      name: "presenterDuty",
    },
    topicPiName: {
      valid: false,
      name: "topicPiName",
    },
    scoreTotal: {
      valid: false,
      name: "scoreTotal",
    },
    scoreInitialAverage: {
      valid: false,
      name: "scoreInitialAverage",
    },
    scoreInitialTotal: {
      valid: false,
      name: "scoreInitialTotal",
    },
    scoreValidTotal: {
      valid: false,
      name: "scoreValidTotal",
    },
    scoreFinalAverage: {
      valid: false,
      name: "scoreFinalAverage",
    },
  });
  const validatorField = (name: any, value: any) => {
    if (name === validatorInput[name].name) {
      if (!value) {
        setValidatorInput({
          ...validatorInput,
          [name]: {
            ...validatorInput[name],
            valid: true,
          },
        });
      } else {
        setValidatorInput({
          ...validatorInput,
          [name]: {
            ...validatorInput[name],
            valid: false,
          },
        });
      }
    }
  };

  useEffect(() => {
    document.querySelectorAll("#form-science-minutes textarea").forEach((element: any) => {
      autoResize(element);
    });
  }, [dataMinutes?.members]);

  useEffect(() => {
    getDetails(id);
  }, [id]);

  useEffect(() => {
    if(!scienceCommitteeId) return;
    getCommitteesInfo(scienceCommitteeId);
  }, [scienceCommitteeId]);

  useEffect(() => {
    if (topicDetails) {
      setDataMinutes((prev) => ({
        ...prev,
        topicName: topicDetails?.name,
        supervisor: "bệnh viện đa khoa Sóc Sơn",
        implementingAgency: "bệnh viện đa khoa Sóc Sơn",
        topicPiName: topicDetails.members?.find(
          (member: TMember) => member.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
        )?.name,
        date: topicDetails?.scienceRecordDate,
        place: topicDetails?.scienceRecordPlace,
        scienceCommitteeCode: topicDetails?.scienceCommitteeCode,
        scienceCommitteeDate: topicDetails?.scienceCommitteeDate,
        scienceCommitteeMembers: topicDetails?.scienceCommitteeMembers,
        members: topicDetails?.members,
      }));
    }
  }, [topicDetails]);

  const handleChange = (e: any) => {
    const { name, value, type } = e.target;
    if (type === "radio") {
      const inputValue = Number(value);
      setDataMinutes({ ...dataMinutes, [name]: inputValue });
    }
    if (type !== "radio") {
      autoResize(e.target);
      setDataMinutes({ ...dataMinutes, [name]: type === "number" ? Number(value) : value });
    }
    if (name === "numAttendees") {
      setDataMinutes((prevData) => {
        const newnumAttendees = Number(value);
        return {
          ...prevData,
          [name]: newnumAttendees,
          numAbsent: 5 - newnumAttendees,
        };
      });
    }
  };
  const addDataMinute = async (data: any) => {
    try {
      const response = await regisScienceMinute(id, data);
      if (response?.data?.code === CODE.SUCCESS) {
        toast.success(`${data?.id ? "Cập nhật" : "Tạo"} biên bản thành công`);
        setDataMinutes((prev: any) => ({
          ...response?.data?.data,
          members: prev?.members,
          scienceCommitteeMembers: prev?.scienceCommitteeMembers,
        }));
      }
    } catch (e) {
      toast.error(`${data?.id ? "Cập nhật" : "Tạo"} biên bản thất bại`);
    }
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!dataMinutes.numAttendees) {
      toast.warning("Vui lòng nhập đủ thông tin!");
      return;
    }
    addDataMinute({
      ...dataMinutes,
      ...committeeInfo
    });
  };

  const autoResize = (textarea: any) => {
    const name = textarea.name;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
    setNumRows((prev: any) => ({ ...prev, [name]: textarea.scrollHeight / 24 }));
  };

  const getDetails = async (id: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(id);
      if (data?.code === CODE.SUCCESS) {
        setTopicDetails(data?.data);
        setScienceCommitteeId(data?.data?.scienceCommitteeId);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getCommitteesInfo = async (committeeId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getCommittees(committeeId);
      if (data?.code === CODE.SUCCESS) {
        setCommitteeInfo(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleYesClick = async () => {
    try {
      if (!signer || !dataMinutes?.id) return;
      let api = null;
      switch (signer) {
        case "secretary":
          api = secretarySignScience(dataMinutes?.id);
          break;
        case "chairman":
          api = chairmanSignScience(dataMinutes?.id);
          break;
        default:
          break;
      }
      if (!api) return;
      let { data } = await api;
      if (data?.code === CODE.SUCCESS) {
        let keyPrefix = signer?.replace(/^\s*\w/, (c) => c.toUpperCase());
        setDataMinutes((prev: any) => ({
          ...prev,
          [`allow${keyPrefix}Sign`]: false,
          [`is${keyPrefix}Signed`]: true,
        }));
        setOpenConfirmDialog(false);
        toast.success(`Ký biên bản thành công!`);
      } else {
        toast.error(`Ký biên bản thất bại`);
        setOpenConfirmDialog(false);
      }
    } catch (error) {
      console.error(error);
      setOpenConfirmDialog(false);
    }
  }
  
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      className="spaces h-calc-vh-50"
      scrollable
    >
      <form onSubmit={handleSubmit}>
        <div className="stepper stepper-links d-flex flex-column">
          <Modal.Header className="header-modal min-h-40px" closeButton>
            <Modal.Title className="py-4">
              Biên bản họp hội đồng khoa học
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="spaces p-0 bg-white mxh-60vh">
            <div className="a4-page spaces px-24" id="form-science-minutes">
              <div className="a4-container">
                <div className="d-flex justify-content-between w-100 mb-n6 mt-3">
                  <em className="underline">Quy trình đăng ký, phê duyệt đề tài NCKH cấp cơ sở</em>
                  <b className="border border-dark spaces px-5">{`BVĐKSS.NCKH.${moment().format("YYYY")}.08`}</b>
                </div>
                <div className="header-container">
                  <div className="left-column center-text">
                    <h3 className="bold">SỞ Y TẾ HÀ NỘI</h3>
                    <h3 className="bold underline">
                      BỆNH VIỆN ĐA KHOA SÓC SƠN{" "}
                    </h3>
                  </div>
                  <div className="right-column center-text">
                    <p className="bold spaces mb-4">
                      CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
                    </p>
                    <p className="underline bold">
                      Độc lập – Tự do – Hạnh phúc
                    </p>
                  </div>
                </div>

                <h3 className="text-center bold section">
                  BIÊN BẢN HỌP HỘI ĐỒNG XÉT DUYỆT ĐỀ CƯƠNG
                </h3>
                <h3 className="text-center">
                  NGHIÊN CỨU KHOA HỌC CẤP CƠ SỞ NĂM {moment(dataMinutes?.regisPeriod).format("YYYY")}
                </h3>

                <div className="section">
                  <h4 className="mb-0">A. NHỮNG THÔNG TIN CHUNG</h4>
                  <div className="mb-4">
                    <div className="spaces min-w-80 fw-bold">I. Tên đề tài:</div>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        wrap="hard"
                        name="topicName"
                        value={dataMinutes?.topicName}
                        style={generalTextareaStyle}
                        readOnly={true}
                      />
                      {Array.from({
                        length: isNaN(numRows?.topicName)
                          ? 1
                          : numRows?.topicName,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `calc(${(1 / numRows?.topicName || 0) * 100 * index}% - 1px)`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="mb-2 d-flex">
                    <div className="spaces min-w-80 fw-bold">Chủ nhiệm đề tài:</div>
                    <span className="relative flex-1 spaces text-indent-6">
                      <input
                        readOnly={true}
                        className="no-padding custom-input d-inline-block"
                        type="text"
                        name="topicPiName"
                        value={dataMinutes?.topicPiName}
                      />
                      <span className="dot-line" style={{ bottom: "-12px" }}></span>
                    </span>
                  </div>
                  <div className="mb-2 d-flex">
                    <div className="spaces min-w-80 fw-bold">Thư ký đề tài:</div>
                    <span className="relative flex-1 spaces text-indent-6">
                      <input
                        readOnly={true}
                        className="no-padding custom-input d-inline-block"
                        type="text"
                         value={dataMinutes?.members?.find((member: TMember) => member?.typeId === MEMBER_TYPE.THU_KY)?.name ?? ""}
                      />
                      <span className="dot-line" style={{ bottom: "-12px" }}></span>
                    </span>
                  </div>
                  <div className="mb-2">
                    <div className="spaces min-w-80 fw-bold">Cán bộ tham gia đề tài:</div>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        wrap="hard"
                        readOnly={true}
                        name="listCanBo"
                        value={dataMinutes?.members?.reduce((listCanBo: string, member: TMember) => {
                          if (member?.typeId === MEMBER_TYPE.CAN_BO_THUC_HIEN && member?.name) {
                            return `${listCanBo}- ${member?.name ?? ""}\n`;
                          }
                          return listCanBo;
                        }, "")?.trimEnd() ?? ""}
                        style={generalTextareaStyle}
                      />
                      {Array.from({
                        length: isNaN(numRows?.listCanBo)
                          ? 1
                          : numRows?.listCanBo,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `calc(${(1 / numRows?.listCanBo || 0) * 100 * index}% - 1px)`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="spaces min-w-80 fw-bold">II. Quyết định thành lập Hội đồng:</div>
                    <div className="spaces ml-20">
                      - Số :{""} <span className="relative">
                        <input
                          readOnly={true}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="scienceCommitteeCode"
                          value={dataMinutes?.scienceCommitteeCode}
                          style={{
                            width: "70px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        />
                        <span className="dot-line"></span>
                      </span>/QĐ-BVĐKSS ngày {""}
                      <span className="relative">
                        <input
                          readOnly={true}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="issueDay"
                          value={moment(dataMinutes?.scienceCommitteeDate).format("DD")}
                          style={{
                            width: "25px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        />
                        <span className="dot-line"></span>
                      </span>{""}/{""}
                      <span className="relative">
                        <input
                          readOnly={true}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="issueMonth"
                          value={moment(dataMinutes?.scienceCommitteeDate).format("MM")}
                          style={{
                            width: "25px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        />
                        <span className="dot-line"></span>
                      </span>{""}/{""}
                      <span className="relative">
                        <input
                          readOnly={true}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="issueYear"
                          value={moment(dataMinutes?.scienceCommitteeDate).format("YYYY")}
                          style={{
                            width: "40px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        />
                        <span className="dot-line"></span>
                      </span> của Giám Đốc Bệnh viện đa khoa Sóc Sơn
                    </div>
                  </div>
                  <div className="mb-4 fw-bold">III. Thời gian và địa điểm họp Hội đồng:</div>
                  <div className="mb-4 d-flex">
                    <div className="spaces min-w-80 ml-20"> - Thời gian:</div>
                    <span className="relative flex-1 spaces text-indent-6">
                      <input
                        readOnly={!dataMinutes?.allowUpdating}
                        className="no-padding custom-input d-inline-block pe-2"
                        type="date"
                        name="date"
                        value={dataMinutes?.date || moment().format("YYYY-MM-DD")}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line" style={{ bottom: "-12px" }}></span>
                    </span>
                  </div>
                  <div className="mb-4 d-flex">
                    <div className="spaces min-w-80 ml-20"> - Địa điểm:</div>
                    <span className="relative flex-1 spaces text-indent-6">
                      <input
                        readOnly={!dataMinutes?.allowUpdating}
                        className="no-padding custom-input d-inline-block"
                        type="text"
                        name="place"
                        value={dataMinutes?.place || committeeInfo?.place}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line" style={{ bottom: "-12px" }}></span>
                    </span>
                  </div>
                  <div className="fw-bold">IV. Thành phần dự họp</div>
                  <div className="mb-2 fw-bold">1. Thành viên Hội đồng</div>

                  <p className="spaces ml-20">
                    - Thành viên có mặt: {""}
                    <span className="relative">
                      <input
                        readOnly={!dataMinutes?.allowUpdating}
                        className="no-padding custom-input"
                        type="number"
                        name="numAttendees"
                        value={dataMinutes?.numAttendees ?? ""}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        style={{
                          width: "80px",
                          display: "inline-block",
                          textAlign: "center",
                          marginLeft: "8px",
                        }}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value >= 0 && value <= 5) {
                            handleChange(e);
                          } else {
                            e.target.value =
                              dataMinutes?.numAttendees !== undefined
                                ? String(dataMinutes.numAttendees)
                                : "";
                          }
                        }}
                      />
                      <span
                        className="dot-line"
                        style={{
                          color: `${validatorInput.numAttendees.valid ? "red" : "black"
                            }`,
                        }}
                      ></span>
                    </span>
                    - Thành viên vắng mặt: {""}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="numAbsent"
                        value={dataMinutes?.numAbsent}
                        style={{
                          width: "80px",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                        readOnly={true}
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <Table bordered className="table-bordered">
                    <thead>
                      <tr>
                        <td style={{ verticalAlign: "middle", fontSize: "12px" }} className="spaces width-7 px-10 py-6 text-center fw-bold">
                          TT
                        </td>
                        <td style={{ verticalAlign: "middle", fontSize: "12px" }} className="spaces width-38 px-10 py-6 text-center fw-bold">
                          HỌ VÀ TÊN
                        </td>
                        <td style={{ verticalAlign: "middle", fontSize: "12px" }} className="spaces width-30 px-10 py-6 text-center fw-bold">
                          CHỨC VỤ, KHOA/PHÒNG CÔNG TÁC
                        </td>
                        <td style={{ verticalAlign: "middle", fontSize: "12px" }} className="spaces width-25 px-10 py-6 text-center fw-bold">
                          CHỨC DANH TRONG HĐ
                        </td>
                      </tr>
                      {dataMinutes?.scienceCommitteeMembers?.map((item: any, index: any) => (
                        <tr key={index + ""}>
                          <td style={{ verticalAlign: "middle", fontSize: "12px" }} className="spaces px-10 py-6 text-center">
                            {index + 1}
                          </td>
                          <td style={{ verticalAlign: "middle", fontSize: "12px" }} className="spaces px-10 py-6">
                            {item?.name ?? ""}
                          </td>
                          <td style={{ verticalAlign: "middle", fontSize: "12px" }} className="spaces px-10 py-6">
                            {item?.position ?? ""}
                          </td>
                          <td style={{ verticalAlign: "middle", fontSize: "12px" }} className="spaces px-10 py-6">
                            {item?.role ?? ""}
                          </td> 
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                    </tbody>
                  </Table>
                  <div className="mb-4">
                    <div className="spaces min-w-80 fw-bold">2. Khách mời</div>
                    <div className="relative">
                      <textarea
                        readOnly={!dataMinutes?.allowUpdating}
                        className="no-padding custom-input"
                        wrap="hard"
                        name="guest"
                        value={dataMinutes?.guest}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.guest)
                          ? 1
                          : numRows?.guest,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `calc(${(1 / numRows?.guest || 0) * 100 * index}% - 1px)`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <h4 className="mb-0">B. NỘI DUNG LÀM VIỆC CỦA HỘI ĐỒNG</h4>
                  <div className="mb-2 fw-bold">I. Thủ tục hành chính.</div>
                  <div className="spaces ml-10">- Khai mạc buổi họp Hội đồng</div>
                  <div className="spaces ml-10">- Thư ký hội đồng đọc Quyết định về việc thành lập Hội đồng đánh giá, xét duyệt đề cương đề tài NCKH cấp cơ sở năm {moment(dataMinutes?.regisPeriod).format("YYYY")}</div>
                  <div className="spaces ml-10">- Đại diện nhóm nghiên cứu báo cáo tóm tắt đề tài.</div>
                  <div className="mb-2 fw-bold">II. Hội đồng nhận xét, đánh giá thuyết minh đề tài</div>
                  <div className="relative">
                    <textarea
                      readOnly={!dataMinutes?.allowUpdating}
                      className="no-padding custom-input"
                      wrap="hard"
                      name="committeeFeedback"
                      value={dataMinutes?.committeeFeedback}
                      onChange={(e) => handleChange(e)}
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "16px",
                        display: "block",
                        zIndex: "1",
                        width: "100%",
                        outline: "unset",
                        position: "relative",
                        resize: "none",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        height: "auto",
                        minHeight: "24px",
                        backgroundColor: "transparent",
                      }}
                    />
                    {Array.from({
                      length: isNaN(numRows?.committeeFeedback)
                        ? 1
                        : numRows?.committeeFeedback,
                    })?.map((item: any, index: any) => (
                      <span
                        key={item}
                        style={{
                          position: "absolute",
                          top: `calc(${(1 / numRows?.committeeFeedback || 0) * 100 * index}% - 1px)`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}

                      ></span>
                    ))}
                  </div>
                  <div className="mb-2 fw-bold">III. Kết luận Hội đồng:</div>
                  <div className="d-flex spaces ml-10">- Đồng ý thông qua/ Không thông qua: {""}
                    <span className="relative flex-1 spaces text-indent-6">
                      <input
                        readOnly={!dataMinutes?.allowUpdating}
                        className="no-padding custom-input d-inline-block"
                        type="text"
                        name="committeeConclusionName"
                        value={dataMinutes?.committeeConclusionName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line" style={{ bottom: "-12px" }}></span>
                    </span>
                  </div>
                  <div className="d-flex spaces ml-10">- Điểm trung bình của đề cương: {""}
                    <span className="relative flex-1 spaces text-indent-6">
                      <input
                        readOnly={!dataMinutes?.allowUpdating}
                        className="no-padding custom-input d-inline-block no-spinners"
                        type="number"
                        name="scoreFinalAverage"
                        value={dataMinutes?.scoreFinalAverage}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line" style={{ bottom: "-12px" }}></span>
                    </span>
                  </div>
                  <div className="spaces text-indent-17">Căn cứ kết quả trên, Hội đồng đề nghị nhóm nghiên cứu cần chỉnh sửa, hoàn thiện lại thuyết minh theo kiến nghị của Hội đồng và gửi về Phòng Kế hoạch tổng hợp sau 7 ngày (tính từ ngày phê duyệt ĐC). Quá thời hạn trên đề cương sẽ không được chấp thuận tiếp tục nghiên cứu.</div>
                </div>

                <div className="signature-container">
                  <div className="left-signature">
                    <p>
                      <b>THƯ KÝ</b>
                    </p>
                    <p className="pt-4">{dataMinutes?.secretaryName}</p>
                    <i>{dataMinutes?.isSecretarySigned ? "(Đã ký)" : ""}</i>
                    {dataMinutes?.allowSecretarySign && (
                      <p className="pt-4">
                        <Button
                          variant="primary"
                          className="min-w-75px fw-bold"
                          type="button"
                          onClick={() => {
                            setOpenConfirmDialog(true);
                            setSigner("secretary");
                          }}
                        >
                          {"Ký"}
                        </Button>
                      </p>
                    )}
                  </div>

                  <div className="right-signature">
                    <p>
                      <b>CHỦ TỊCH HỘI ĐỒNG</b>
                    </p>
                    <p className="pt-4">{dataMinutes?.chairmanName}</p>
                    <i>{dataMinutes?.isChairmanSigned ? "(Đã ký)" : ""}</i>
                    {dataMinutes?.allowChairmanSign && (
                      <p className="pt-4">
                        <Button
                          variant="primary"
                          className="min-w-75px fw-bold"
                          type="button"
                          onClick={() => {
                            setOpenConfirmDialog(true);
                            setSigner("chairman");
                          }}
                        >
                          {"Ký"}
                        </Button>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex justify-content-center">
            <Button
              variant="primary"
              className="min-w-75px fw-bold"
              type="submit"
            >
              Lưu
            </Button>
            <Button
              variant="secondary"
              className="min-w-75px fw-bold"
              onClick={onHide}
            >
              Đóng
            </Button>
          </Modal.Footer>
          {openConfirmDialog && (
            <ConfirmDialog
              show={openConfirmDialog}
              yes="Xác nhận"
              onYesClick={() => handleYesClick()}
              message={`Bạn có chắc muốn ký biên bản này?`}
              close="Huỷ"
              onCloseClick={() => setOpenConfirmDialog(false)}
              title="Xác nhận thao tác"
            />
          )}
        </div>
      </form>
    </Modal>
  );
};
export default ScienceMinutesDialog;
