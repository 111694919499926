import { Form, Formik, FormikValues } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { CODE, RESPONSE_MESSAGE } from "../../utils/Constant";
import { checkBatchOfRegistration, getTopicRecognitionById, saveTopicRecognition, updateTopicRecognition } from "../services/services";
import Step1 from "./Step1";
import Step2 from "./Step2";

interface Props {
  onHide: () => void;
  onRefresh: () => void;
  id?: string;
  isView?: boolean
}

const AddTopicRecognitionDialog = (props: Props) => {
  const { onHide, onRefresh, id, isView } = props;

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const formRef = useRef<any>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [selectedTopics, setSelectedTopics] = useState<any[]>([]);

  const validationSchema = Yup.object().shape({
    regisPeriod: Yup.object()
      .nullable()
      .test("required-if-no-id", "Vui lòng nhập thông tin!", function (value) {
        return !!id || !!value;
      }),
  });

  const updatePageData = async () => {
    try {
      let { data } = await getTopicRecognitionById(id!);
      if (data?.code === CODE.SUCCESS) {
        formRef.current?.setValues({
          ...formRef.current?.values,
          ...data?.data ?? {},
          regisPeriod: {
            id: data?.data?.regisPeriodId,
            name: data?.data?.regisPeriodName,
            year: data?.data?.regisPeriodYear
          }
        });
        setSelectedTopics(data?.data?.topics || []);
      }
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
    }
  };

  useEffect(() => {
    if (id) {
      updatePageData();
    }
  }, [id]);

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const getTitle = () => {
    let prefix = "";
    if (id) {
      prefix = "Cập nhật";
    } else {
      prefix = "Thêm mới";
    }

    return `${!isView ? prefix : ""} quyết định công nhận`;
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    setIsSubmit(false);
    stepper.current.goPrev();
  };

  const submitStep = async (values: any, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber && !isSubmit) {
      if (id) {
        stepper.current?.goNext();
        setIsSubmit(true);
      } else {
        await handleCheckBatchOfRegistration(values.regisPeriod?.id, () => {
          stepper.current?.goNext();
          setIsSubmit(true);
        });
      }
    } else if (isSubmit) {
      await handleSaveTopicRecognition(values);
    }
  };

  const handleCheckBatchOfRegistration = async (regisPeriodId: string, callback?: () => void) => {
    try {
      let { data } = await checkBatchOfRegistration(regisPeriodId);
      if (CODE.SUCCESS === data?.code) {
        callback?.();
      } else {
        let errorMesage = data?.data?.[0]?.errorMessage ?? RESPONSE_MESSAGE.ERROR;
        toast.warn(errorMesage);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveTopicRecognition = async (values: any) => {
    try {
      let submitData = {
        id: id,
        ...values,
      };
      let { data } = id ? await updateTopicRecognition(submitData) : await saveTopicRecognition(submitData);
      if (CODE.SUCCESS === data?.code) {
        toast.success(`${id ? "Cập nhật" : "Thêm"} quyết định công nhận thành công`);
        onHide();
        onRefresh();
      } else {
        let errorMesage = data?.data?.[0]?.errorMessage ?? RESPONSE_MESSAGE.ERROR;
        toast.warn(errorMesage);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handleSubmitStep = () => {
    formRef.current?.handleSubmit();
  };

  return (
    <Modal
      centered
      show={true}
      onHide={onHide}
      size="xl"
      className="spaces h-calc-vh-50"
      scrollable
      backdrop="static"
    >
      <div
        ref={stepperRef}
        className="stepper stepper-links d-flex flex-column"
        id="kt_create_account_stepper"
      >
        <Modal.Header className="header-modal min-h-40px" closeButton>
          <Modal.Title className="py-4">{getTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="spaces p-0 bg-gray mxh-65vh science-council">
          <Formik<any>
            innerRef={formRef}
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              regisPeriod: null,
              code: "",
              regisPeriodYear: "",
              decisionNumber: "",
              issueDay: moment(new Date()).date(),
              issueMonth: moment(new Date()).month() + 1,
              issueYear: moment(new Date()).year(),
              implementationYear: "",
              topics: [],
            }}
            onSubmit={submitStep}
          >
            {() => (
              <Form>
                {
                  (isView) ? <Step2 isView={isView} onRefresh={onRefresh} />
                    : <div className="card">
                      <div className="card-body p-0">
                        <div
                          ref={stepperRef}
                          className="stepper stepper-links d-flex flex-column"
                          id="kt_create_account_stepper"
                        >
                          <div className="stepper-nav">
                            <div
                              className="stepper-item current"
                              data-kt-stepper-element="nav"
                            >
                              <h3 className="stepper-title">1. Đợt đăng ký/ đề tài</h3>
                            </div>

                            <div className="stepper-item" data-kt-stepper-element="nav">
                              <h3 className="stepper-title">
                                2. Quyết định công nhận
                              </h3>
                            </div>
                          </div>
                          <div id="kt_create_council-form" className="mx-auto w-100">
                            <div
                              className="current px-10 py-4"
                              data-kt-stepper-element="content"
                            >
                              <Step1
                                selectedTopics={selectedTopics}
                                isView={isView}
                                handleCheckBatchOfRegistration={handleCheckBatchOfRegistration}
                              />
                            </div>

                            <div data-kt-stepper-element="content" className="bg-gray px-10 py-4">
                              <Step2
                                isView={isView}
                                onRefresh={onRefresh}
                              />
                            </div>
                          </div>
                          </div>
                      </div>
                    </div>
                }
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-stack w-100">
            <div className="mr-2">
              <button
                onClick={prevStep}
                type="button"
                className="btn btn-primary me-3"
                data-kt-stepper-action="previous"
              >
                <i className="bi bi-chevron-bar-left m-0"></i>{""}
                Quay lại
              </button>
            </div>

            {isView ? (
              <Button
                variant="secondary"
                className="min-w-75px fw-bold"
                onClick={onHide}
              >
                Đóng
              </Button>
            ) : (
              <div>
                <button
                  type="submit"
                  className="btn btn-lg btn-primary"
                  onClick={handleSubmitStep}
                >
                  <span className="indicator-label">
                    {isSubmit ? (
                      <span className="spaces px-10">Lưu </span>
                    ) : (
                      <>
                        <span>Tiếp theo </span>
                        <i className="bi bi-chevron-bar-right m-0"></i>
                      </>
                    )}
                  </span>
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default AddTopicRecognitionDialog;
