import {FC, useContext} from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {checkIsActive, KTSVG, toAbsoluteUrl} from '../../../../helpers'
import { menu } from '../../../../../app/modules/appContext/AppContextModel'
import { localStorageItem } from '../../../../../app/modules/utils/LocalStorage'
import { AppContext } from '../../../../../app/modules/appContext/AppContext'
import { hasAuthority } from '../../../../../app/modules/utils/Permission'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  menu?: menu
  hasDropdown?: boolean
  subMenus?: menu[]
}

const MenuItem: FC<Props> = ({to, title, icon, fontIcon, hasArrow = false, hasBullet = false, menu, hasDropdown = false, subMenus }) => {
  const {pathname} = useLocation();
  const { setEventKey } = useContext(AppContext);

  const handleClickMenuItem = (id: string | undefined, key: string | undefined) => {
    let data = localStorageItem.get(key) ? localStorageItem.get(key) : [];
    if (!data.includes(id)) {
      data.push(id);
      data.sort((a: string, b: string) => a > b ? 1 : -1);
      localStorageItem.set(key, data)
    }
    setEventKey(id)
  }

  return (
    <>
      {hasDropdown ? (
        <>
          <div
            className={clsx(
              "cursor-pointer d-flex align-items-center symbol-35px symbol-md-40px menu-dropdown"
            )}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-start"
          >
            <div className="spaces px-8">
              <Link
                onDragStart={(e: any) => {
                  e.preventDefault();
                }}
                className={clsx("menu-link py-4 flex gap-2 align-items-center", {
                  "active menu-here": pathname.includes(to),
                })}
                to={"#"}
              >
                <span className="menu-title" style={{color: `${pathname.includes(to) ? '#ffc107' : 'white'}`}}>{title}</span>
                <i className="bi bi-caret-down-fill" style={{color: `${pathname.includes(to) ? '#ffc107' : 'white'}`}}></i>
              </Link>
            </div>
          </div>
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-gray-600 menu-state-bg menu-state-primary w-auto fw-bold fs-6"
            data-kt-menu="true"
          >
            <div className="p-0">
              <div className="menu-content d-flex align-items-center">
                <div className="flex-column">
                  {subMenus?.map((menu, index) => {
                    if(hasAuthority(menu?.authority || "")) {
                      return (
                        <MenuItem
                          key={index}
                          to={menu?.to}
                          title={menu?.title}
                        />
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className="menu-item me-lg-1"
          onClick={() => handleClickMenuItem(menu?.id, menu?.key)}
        >
          <Link
            onDragStart={(e: any) => {
              e.preventDefault();
            }}
            className={clsx("menu-link py-4", {
              "active menu-here": checkIsActive(pathname, to),
            })}
            to={to}
          >
            {hasBullet && (
              <span className="menu-bullet">
                <span className="bullet bullet-dot"></span>
              </span>
            )}

            {icon && (
              <span className="menu-icon">
                <KTSVG path={icon} className="svg-icon-2" />
              </span>
            )}

            {fontIcon && (
              <span className="menu-icon">
                <i className={clsx("bi fs-3", fontIcon)}></i>
              </span>
            )}

            <span className="menu-title text-white">{title}</span>

            {hasArrow && <span className="menu-arrow text-white"></span>}
          </Link>
        </div>
      )}
    </>
  );
}

export {MenuItem}
