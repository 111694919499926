import { Form, Formik } from "formik";
import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { TextFieldCustom } from "../../category-personnel/components/text-field-custom/TextFieldCustom";
import AutocompleteObjectV2 from '../../component/AutocompleteObjectV2';
import { generateYears } from "../../utils/AppFunction";
import "../TopicRecognition.scss";
import { TopicRecognitionContext } from "../TopicRecognitionContext";
import { initFilter, TBatchOfRegistrationFilter } from "../models/TopicRecognitionModel";

export default function Filter() {

  const { filter, setFilter } = useContext(TopicRecognitionContext);

  const validationSchema = Yup.object({});

  const handleSubmit = async (values: TBatchOfRegistrationFilter) => {
    setFilter({...filter, ...values});
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initFilter}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="batch-of-registration-filter spaces px-10 py-10 bg-white mb-4">
            <Row>
              <Col xs={3}>
                <div className="flex align-items-center">
                  <div className="label pe-4 fw-bold">Năm</div>
                  <div className="flex-1">
                    <AutocompleteObjectV2
                      options={generateYears()}
                      isSearchDefauilt={true}
                      name={"yearOption"}
                      onChange={(e: any) =>  {
                        setFieldValue("year", e?.id || null);
                        setFieldValue("yearOption", e);
                      }}
                      value={values.yearOption || generateYears()?.[0]}
                      errors={errors.year}
                      searchObject={{}}
                      getOptionLabel={(e: any) => e?.title}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={3}>
                <div className="flex align-items-center">
                  <div className="label pe-4 fw-bold">Từ khóa</div>
                  <div className="flex-1">
                    <TextFieldCustom
                      type="text"
                      title=""
                      name="keyword"
                      placeholder="Nhập số quyết định"
                      values={values?.keyword}
                      errors={""}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={3} className="flex align-items-stretch">
                <button
                  type="submit"
                  onClick={() => {}}
                  className="btn-outline btn-filter"
                >
                  <i className="bi bi-filter text-pri fs-3"></i>{""}
                  Lọc
                </button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
}
