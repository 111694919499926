import axios from "axios";
import { IParamsSimpleCategory, IParamsTermClasses } from "../models/params";
import { DEFAULT_PAGE_INDEX, SEARCH_OBJECT_MAX_SIZE } from "./Constant";
import { toast } from "react-toastify";
import { localStorageItem } from "./LocalStorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";

const API_PATH_EMR = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];
const API_PATH_TERM_CLASSES = API_PATH_EMR + "/term-classes";
const API_PATH_CATEGORY = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] + "/simple-categories";
const API_PATH_PROVINCES = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] + "/provinces";
const API_PATH_DISTRICTS = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] + "/districts";
const API_PATH_SUB_DISTRICTS = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] + "/subdistricts";

export const getSimpleCategory = (params: IParamsSimpleCategory) => {
    let url = API_PATH_CATEGORY + "/search";
    return axios.get(url, { params: {
        ...params,
        ...SEARCH_OBJECT_MAX_SIZE
    } });
};

export const getTermByClassId = (params: {
    classId: number
}) => {
    let url = API_PATH_EMR + "/terms";
    return axios.get(url, { params });
};

export const getTermClasses = (params: IParamsTermClasses) => {
    let url = API_PATH_TERM_CLASSES + "/terms";
    return axios.get(url, { params });
};

export const getAllProvinces = () => {
    const MAX_PAGE_SIZE_PROVINCE = 63;
    let params = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE_PROVINCE
    };
    let url = API_PATH_PROVINCES + "/search";
    return axios.get(url, { params });
};

export const getAllDistricts = () => {
    let params = SEARCH_OBJECT_MAX_SIZE;
    let url = API_PATH_DISTRICTS + "/search";
    return axios.get(url, { params });
};

export const getAllSubDistricts = () => {
    let params = SEARCH_OBJECT_MAX_SIZE;
    let url = API_PATH_SUB_DISTRICTS + "/search";
    return axios.get(url, { params });
};

export const getNotifications = (data: any) => {
  let url = `${API_PATH_EMR}/notifications`;
  return axios.get(url, { params: data });
};

export const getCountUnreadNoti = () => {
  let url = `${API_PATH_EMR}/notifications/unread`;
  return axios.get(url);
};

export const maskAsReadNoti = (notificationUserId: string) => {
  let url = `${API_PATH_EMR}/notifications/${notificationUserId}/mark-as-read`;
  return axios.post(url);
};

export const handleDownloadFile = (fileId: string, fileName: string) => {
    const token = localStorageItem.get(KEY_LOCALSTORAGE.AUTH_LOCAL_STORAGE_KEY)?.access_token;
    if (!fileId || !fileName) {
        toast.warning("Không có gì để tải xuống");
        return;
    }
    const url = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] + '/files/' + fileId + '/download';
    const headers = new Headers({
        'Authorization': `Bearer ${token}`,
    });
    fetch(url, { headers })
        .then(response => response.blob())
        .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        })
        .catch(error => {
            console.error(error);
            toast.error("Lỗi tải file");
        });
}

export const handleDownloadSampleFile = (fileName: string, apiPath: string) => {
    const token = localStorageItem.get(KEY_LOCALSTORAGE.AUTH_LOCAL_STORAGE_KEY)?.access_token;
    if (!fileName || !apiPath) {
        toast.warning("Không có gì để tải xuống");
        return;
    }
    const url = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] + apiPath;
    const headers = new Headers({
        'Authorization': `Bearer ${token}`,
    });
    fetch(url, { headers })
        .then(response => response.blob())
        .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        })
        .catch(error => {
            console.error(error);
            toast.error("Lỗi tải file");
        });
}