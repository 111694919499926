import React, { useEffect, useState } from 'react'
import AutocompleteV2 from '../../component/AutocompleteObjectV2';
import { useFormikContext } from 'formik';
import { searchBatchOfRegistration } from '../../dang-ky-de-tai/services/services';
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, SELECTION_MODE, TRANG_THAI } from '../../utils/Constant';
import { TableCustom } from '../../component/table/table-custom/TableCustom';
import { OctTablePagination } from '@oceantech/oceantech-ui';
import { TableCustomCell } from '../../component/table/components/TableCustomCell';
import { TableCustomHeader } from '../../component/table/components/TableCustomHeader';
import { Column } from 'react-table';
import { uniqueArray } from '../../utils/AppFunction';
import { searchTopic } from '../../quan-ly-de-tai/de-tai/services/services';
import { toast } from 'react-toastify';
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from '../../utils/PageUtils';
import moment from 'moment';

type Props = {
    selectedTopics?: any[];
    isView?: boolean;
    details?: any;
    handleCheckBatchOfRegistration?: (regisPeriodId: string, callback?: () => void) => void
}

const Step1 = (props: Props) => {
    const { isView, details, handleCheckBatchOfRegistration, selectedTopics } = props;
    const { values, setValues, setFieldValue, errors, touched } = useFormikContext<any>();

    const [totalElements, setTotalElements] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
    const [filter, setFilter] = useState<any>({});
    const [topicList, setTopicList] = useState<any[]>([]);

    useEffect(() => {
        values.regisPeriod?.year && getTopicList(values.regisPeriod?.year);
    }, [filter, values.regisPeriod?.year]);

    useEffect(() => {
        setFilter({ ...filter, pageIndex: page, pageSize: rowsPerPage, statusId: TRANG_THAI.DA_NGHIEM_THU })
    }, [page, rowsPerPage, details]);

    const getTopicList = async (year?: string) => {
        try {
            let { data } = await searchTopic({...filter, year});
            if (data?.code === CODE.SUCCESS) {
                setTopicList(data?.data?.content || []);
                setTotalElements(data?.data?.pageable?.totalElements);
            } else {
                toast.error("Đã có lỗi xảy ra!");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectData = (rows: any[]) => {
        const newRows = uniqueArray(rows);
        setFieldValue('topics', newRows);
    };

    const scienceColumns: ReadonlyArray<Column<any>> = [
        {
            Header: (props: any) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Mã đề tài"}
                    className="text-center text-white align-middle bg-pri min-w-50px"
                />
            ),
            id: "code",
            Cell: ({ ...props }) => (
                <TableCustomCell
                    className="text-center s-2"
                    data={props.data[props.row.index].code}
                />
            ),
        },
        {
            Header: (props: any) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Tên đề tài"}
                    className="text-center text-white align-middle bg-pri min-w-250px"
                />
            ),
            id: "name",
            Cell: ({ ...props }) => (
                <TableCustomCell
                    className="text-center s-2"
                    data={props.data[props.row.index].name}
                />
            ),
        }
    ];
    return (
        <div className="flex flex-column w-100">
            <div className="w-100 mb-4">
                <AutocompleteV2
                    options={[]}
                    isSearchDefauilt={true}
                    name={"regisPeriod"}
                    onChange={(option: any) => {
                        setValues({
                            ...values,
                            regisPeriod: option,
                            code: `BVĐKSS.NCKH.${moment(option?.startDate).year()}.19`,
                            regisPeriodYear: moment(option?.startDate).year(),
                        });
                        option?.id && handleCheckBatchOfRegistration?.(option?.id);
                    }}
                    value={values?.regisPeriod}
                    errors={errors?.regisPeriod}
                    touched={touched?.regisPeriod}
                    searchFunction={searchBatchOfRegistration}
                    searchObject={{
                        pageIndex: DEFAULT_PAGE_INDEX,
                        pageSize: MAX_PAGE_SIZE
                    }}
                    getOptionLabel={(e: any) => e?.name}
                    placeholder='-- Chọn đợt đăng ký --'
                    menuPlacement='bottom'
                    isDisabled={isView || values?.id}
                />
            </div>
            <div className='w-100'>
                <TableCustom<any>
                    className="p-0 table-topic"
                    data={topicList}
                    columns={scienceColumns}
                    selectionMode={SELECTION_MODE.MULTI}
                    verticalScroll={true}
                    getSelectedRowsData={handleSelectData}
                    checkedRows={selectedTopics || []}
                />
                <OctTablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={Math.ceil(totalElements / rowsPerPage)}
                    totalElements={details?.numTopics || 0}
                    rowsForPage={rowsForPage}
                    numberOfElements={0}
                />
            </div>
        </div>
    )
}

export default Step1