import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { ACCEPT_FILE_TYPE, CODE, MEMBER_TYPE, ROLE } from "../../../utils/Constant";
import { TMember } from "../models/TopicModel";
import { addReviewScienceCouncil, getCommittees, getTopicById, signScienceEvaluation } from "../services/services";
import { handleDownloadFile } from "../../../utils/ServicesUtils";
import { ConfirmDialog } from "../../../component/ConfirmDialog";
import AutocompleteV2 from "../../../component/AutocompleteObjectV2";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { hasAuthority } from "../../../utils/Permission";

export type TEvaluationForm = {
  topicId?: string;
  topicName?: string;
  topicPiName?: string;
  topicCoPiName?: string;
  committeeRecordCode?: string;
  committeeRecordDate?: string;
  committeeRecordPlace?: string;
  topicReviewerId?: string;
  reviewerName?: string;
  criterion1?: number;
  criterion2?: number;
  criterion3?: number;
  criterion4?: number;
  criterion5?: number;
  criterion6?: number;
  criterion7?: number;
  criterion8?: number;
  criterion9?: number;
  criterion10: number;
  criterion11?: number;
  sum?: number;
  opinion?: string;
  fileId?: string;
  originalFileName?: string;
  reviewerRole?: string;
  reviewerPosition?: string;
  review?: string;
  reviewDay?: string;
  reviewMonth?: string;
  reviewYear?: string;
  [key: string]: any;
};
const initAddEvaluationForm: TEvaluationForm = {
  topicReviewerId: "",
  reviewerName: "",
  topicName: "",
  topicPiName: "",
  topicCoPiName: "",
  committeeRecordDate: "",
  committeeRecordPlace: "",
  criterion1: 0,
  criterion2: 0,
  criterion3: 0,
  criterion4: 0,
  criterion5: 0,
  criterion6: 0,
  criterion7: 0,
  criterion8: 0,
  criterion9: 0,
  criterion10: 0,
  criterion11: 0,
  sum: 0,
  opinion: "",
  reviewerRole: "",
  reviewerPosition: "",
  review: "",
  reviewDay: moment().date().toString(),
  reviewMonth: (moment().month() + 1).toString(),
  reviewYear: moment().year().toString(),
  members: [],
};

const generalTextareaStyle: any = {
  fontFamily: "Times New Roman",
  fontSize: "16px",
  display: "block",
  zIndex: "1",
  width: "100%",
  outline: "unset",
  position: "relative",
  resize: "none",
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
  height: "auto",
  minHeight: "24px",
  backgroundColor: "transparent",
};

interface Props {
  show: boolean;
  onHide: () => void;
  id?: string;
  councilId?: string;
  onRefresh?: () => Promise<void>;
  dataDetails?: TEvaluationForm;
  isView?: boolean;
}
const EvaluationForm = (props: Props) => {
  const { show, onHide, id, councilId, onRefresh, dataDetails, isView } = props;
  const { setIsLoading } = useContext(AppContext);

  const [valueEvaluation, setValueEvaluation] = useState(initAddEvaluationForm);
  const [file, setFile] = useState<any>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [scienceReviewers, setScienceReviewers] = useState<any[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [numRows, setNumRows] = React.useState<any>({});
  const [openEditFile, setOpenEditFile] = useState<boolean>(false);
  const formRef = useRef<any>(null);

  const validationSchema = Yup.object().shape({
    topicReviewerId: Yup.string().test(
      "required",
      "Vui lòng nhập thông tin",
      () => {
        if (!valueEvaluation?.id && !valueEvaluation?.topicReviewerId) return false;
        return true;
      })
  });

  useEffect(() => {
    if (!id) return;
    getTopicDetails(id);
  }, [id]);

  useEffect(() => {
    if (dataDetails && !valueEvaluation?.id) {
      setValueEvaluation(dataDetails);
    }
  }, [dataDetails]);

  useEffect(() => {
    document.querySelectorAll("#form-evaluation textarea").forEach((element: any) => {
      autoResize(element);
    });
  }, [valueEvaluation?.members]);

  const getTopicDetails = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(topicId);
      if (data?.code === CODE.SUCCESS) {
        setValueEvaluation({
          ...valueEvaluation,
          topicName: data?.data?.name,
          topicPiName: data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
          )?.name,
          topicCoPiName: data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.DONG_CHU_NHIEM
          )?.name,
          members: data?.data?.members,
          scienceCommitteeCode: data?.data?.scienceCommitteeCode,
          scienceCommitteeDate: data?.data?.scienceCommitteeDate
        });
        setScienceReviewers(data?.data?.scienceReviewers || []);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const autoResize = (textarea: any) => {
    const name = textarea.name;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
    setNumRows((prev: any) => ({ ...prev, [name]: textarea.scrollHeight / 24 }));
  };

  const handleChange = (e: any) => {
    const { name, value, type } = e.target;
  if (type ==="number") {
    const { min, max } = e?.target || {};
    if (Number(value) < Number(min)) {
      setValueEvaluation({
        ...valueEvaluation,
        [name]: +min
      })
    } else if (Number(value) > Number(max)) {
      setValueEvaluation({
        ...valueEvaluation,
        [name]: +max
      })
    } else {
      setValueEvaluation({
        ...valueEvaluation,
        [name]: Number(value)
      })
    }
  } else {
    setValueEvaluation({
      ...valueEvaluation,
      [name]: value,
    });
    autoResize(e.target)
  }
  };

  const handleChangeFile = async (e: any) => {
    let file = e?.target?.files?.[0];
    if (!file) return;
    if (![ACCEPT_FILE_TYPE.DOC, ACCEPT_FILE_TYPE.DOCX, ACCEPT_FILE_TYPE.PDF].includes(file.type)) {
      toast.warning("Chi được phép tải lên tệp tin nên định dạng .doc, .docx hoặc .pdf");
      e.target.value = '';
    } else {
      setFile(file);
    }
  };

  const calcSum = () => {
    let sum = Array
      .from({ length: 11 }, (_, index) => index + 1)
      .reduce((accumulator: number, item: number) => (
        accumulator + (valueEvaluation?.[`criterion${item + 1}`] ?? 0)
      ), 0);
    return sum;
  }

  const handleSubmit = async () => {
    let dataReview = {
      ...valueEvaluation,
      topicId: id || valueEvaluation?.topicId,
      sum: calcSum()
    };

    let formData = new FormData();
    if (file && typeof file !== "string") {
      formData.append("file", file);
    };
    Object.entries(dataReview)?.forEach(([key, value]) => {
      value && formData.append(key, String(value));
    });
    try {
      setIsLoading(true);
      let { data } = await addReviewScienceCouncil(formData);
      if (CODE.SUCCESS === data?.code) {
        setValueEvaluation((prev: any) => ({ ...data?.data, members: prev?.members }));
        toast.success("Thêm đánh giá thành công");
        onRefresh?.();
      } else {
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleYesClick = async () => {
    try {
      let { data } = await signScienceEvaluation(valueEvaluation?.id);
      if (data?.code === CODE.SUCCESS) {
        setValueEvaluation({
          ...valueEvaluation,
          signed: true
        });
        setDisabled(!hasAuthority(ROLE.PHONG_NCKH));
        onRefresh?.();
        setOpenConfirmDialog(false);
        toast.success(`Ký phiếu đánh giá thành công!`);
      } else {
        toast.error(`Ký phiếu đánh giá thất bại`);
        setOpenConfirmDialog(false);
      }
    } catch (error) {
      console.error(error);
      setOpenConfirmDialog(false);
    }
  }
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      className="spaces h-calc-vh-50"
      scrollable
    >
      <Modal.Header className="header-modal min-h-40px" closeButton>
        <Modal.Title className="py-4">Phiếu đánh giá thuyết minh đề tài khoa học & công nghệ</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          topicReviewerId: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formRef}
      >
        {({ setFieldValue, errors, touched }) => (
          <FormikForm className="form-evaluation" id="form-evaluation">
            <Modal.Body className="a4-page">
              <div className="d-flex justify-content-between w-100 mb-n6 mt-3">
                <em className="underline">Quy trình đăng ký, phê duyệt đề tài NCKH cấp cơ sở</em>
                <b className="border border-dark spaces px-5">{`BVĐKSS.NCKH.${moment().format("YYYY")}.08`}</b>
              </div>
              <div className="header-container">
                <div className="left-column center-text">
                  <h3 className="bold">SỞ Y TẾ HÀ NỘI</h3>
                  <h3 className="bold underline">
                    BỆNH VIỆN ĐA KHOA SÓC SƠN{" "}
                  </h3>
                </div>
                <div className="right-column center-text">
                  <p className="bold spaces mb-4">
                    CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
                  </p>
                  <p className="underline bold">
                    Độc lập – Tự do – Hạnh phúc
                  </p>
                </div>
              </div>
              <Row>
                <Col xs={12}>
                  <div>
                    <h3 className="text-center">PHIẾU ĐÁNH GIÁ</h3>
                    <h3 className="text-center mb-1 text-uppercase">
                      ĐỀ CƯƠNG ĐỀ TÀI KHOA HỌC VÀ CÔNG NGHỆ CẤP CƠ SỞ
                    </h3>
                  </div>
                  <div className='d-flex justify-content-center w-100 mb-4'>
                    <em className='text-center spaces width-90'>
                      (Quyết định thành lập Hội đồng số: {""} <span className="relative">
                        <input
                          readOnly={true}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="scienceCommitteeCode"
                          value={valueEvaluation?.scienceCommitteeCode}
                          style={{
                            width: "70px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        />
                        <span className="dot-line"></span>
                      </span>/QĐ-BVĐKSS ngày {""}
                      <span className="relative">
                        <input
                          readOnly={true}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="issueDay"
                          value={moment(valueEvaluation?.scienceCommitteeDate).format("DD")}
                          style={{
                            width: "25px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        />
                        <span className="dot-line"></span>
                      </span>{""}/{""}
                      <span className="relative">
                        <input
                          readOnly={true}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="issueMonth"
                          value={moment(valueEvaluation?.scienceCommitteeDate).format("MM")}
                          style={{
                            width: "25px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        />
                        <span className="dot-line"></span>
                      </span>{""}/{""}
                      <span className="relative">
                        <input
                          readOnly={true}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="issueYear"
                          value={moment(valueEvaluation?.scienceCommitteeDate).format("YYYY")}
                          style={{
                            width: "40px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        />
                        <span className="dot-line"></span>
                      </span> của GĐBVĐKSS)
                    </em>
                  </div>

                </Col>
                <div className="mb-4 d-flex align-items-center">
                  <div className="spaces min-w-80">Họ và tên:</div>
                  {
                    (isView || valueEvaluation?.id) ? (
                      <span className="relative flex-1 spaces text-indent-6">
                        <input
                          readOnly={true}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          value={valueEvaluation?.reviewerName}
                        />
                        <span className="dot-line" style={{ bottom: "-12px" }}></span>
                      </span>
                    ) : (
                      <AutocompleteV2
                        options={scienceReviewers}
                        menuPlacement="bottom"
                        name="topicReviewerId"
                        onChange={(option: any) => {
                          setFieldValue("topicReviewerId", option?.id);
                          setValueEvaluation({
                            ...valueEvaluation,
                            topicReviewerId: option?.id,
                            reviewerName: option?.name,
                            reviewerRole: option?.role,
                            reviewerPosition: option?.position
                          });
                        }}
                        getOptionLabel={(e: any) => e?.name}
                        errors={errors?.topicReviewerId}
                        touched={touched?.topicReviewerId}
                        isClearable={false}
                        isDisabled={isView || disabled}
                      />
                    )
                  }
                </div>
                <div className="mb-2 d-flex">
                  <div className="spaces min-w-80">Chức danh trong hội đồng:</div>
                  <span className="relative flex-1 spaces text-indent-6">
                    <input
                      readOnly={true}
                      className="no-padding custom-input d-inline-block"
                      type="text"
                      name="role"
                      value={valueEvaluation?.reviewerRole}
                    />
                    <span className="dot-line" style={{ bottom: "-12px" }}></span>
                  </span>
                </div>
                <div className="mb-2 d-flex">
                  <div className="spaces min-w-80">Chức vụ, đơn vị công tác:</div>
                  <span className="relative flex-1 spaces text-indent-6">
                    <input
                      readOnly={true}
                      className="no-padding custom-input d-inline-block"
                      type="text"
                      name="position"
                      value={valueEvaluation?.reviewerPosition}
                    />
                    <span className="dot-line" style={{ bottom: "-12px" }}></span>
                  </span>
                </div>
                <div className="mb-2">
                  <div className="spaces min-w-80 fw-bold">Nhận xét đề tài:</div>
                  <div className="relative">
                    <textarea
                      className="no-padding custom-input"
                      title="Nhập nhận xét"
                      wrap="hard"
                      readOnly={isView || disabled}
                      name="review"
                      value={valueEvaluation?.review}
                      onChange={(e) => handleChange(e)}
                      style={generalTextareaStyle}
                    />
                    {Array.from({
                      length: isNaN(numRows?.review)
                        ? 1
                        : numRows?.review,
                    })?.map((item: any, index: any) => (
                      <span
                        key={item}
                        style={{
                          position: "absolute",
                          top: `calc(${(1 / numRows?.review || 0) * 100 * index}% - 1px)`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}

                      ></span>
                    ))}
                  </div>
                </div>
                <div className="mb-2 d-flex">
                  <div className="spaces min-w-80">Chủ nhiệm đề tài:</div>
                  <span className="relative flex-1 spaces text-indent-6">
                    <input
                      readOnly={true}
                      className="no-padding custom-input d-inline-block"
                      type="text"
                      name="issueYear"
                      value={valueEvaluation?.topicPiName ?? ""}
                    />
                    <span className="dot-line" style={{ bottom: "-12px" }}></span>
                  </span>
                </div>
                <div className="mb-2 d-flex">
                  <div className="spaces min-w-80">Thư ký đề tài:</div>
                  <span className="relative flex-1 spaces text-indent-6">
                    <input
                      readOnly={true}
                      className="no-padding custom-input d-inline-block"
                      type="text"
                      name="issueYear"
                      value={valueEvaluation?.members?.find((member: TMember) => member?.typeId === MEMBER_TYPE.THU_KY)?.name ?? ""}
                    />
                    <span className="dot-line" style={{ bottom: "-12px" }}></span>
                  </span>
                </div>
                <div className="mb-2">
                  <div className="spaces min-w-80">Cán bộ tham gia nghiên cứu:</div>
                  <div className="relative">
                    <textarea
                      className="no-padding custom-input"
                      wrap="hard"
                      readOnly={true}
                      name="listCanBo"
                      value={valueEvaluation?.members?.reduce((listCanBo: string, member: TMember) => {
                        if (member?.typeId === MEMBER_TYPE.CAN_BO_THUC_HIEN && member?.name) {
                          return `${listCanBo}- ${member?.name ?? ""}\n`;
                        }
                        return listCanBo;
                      }, "")?.trimEnd() ?? ""}
                      style={generalTextareaStyle}
                    />
                    {Array.from({
                      length: isNaN(numRows?.listCanBo)
                        ? 1
                        : numRows?.listCanBo,
                    })?.map((item: any, index: any) => (
                      <span
                        key={item}
                        style={{
                          position: "absolute",
                          top: `calc(${(1 / numRows?.listCanBo || 0) * 100 * index}% - 1px)`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}

                      ></span>
                    ))}
                  </div>
                </div>
                <div className="mb-4 d-flex">
                  <div className="spaces min-w-80">Đơn vị chủ trì đề tài: Bệnh Viện đa khoa Sóc Sơn</div>
                </div>
                <Col xs={12}>
                  <div>
                    <span className="mb-2 pb-2 fw-bold">
                      Các tiêu chí đánh giá:
                    </span>
                    <Table bordered className="table-bordered">
                      <thead>
                        <tr>
                          <td className="spaces width-80 px-10 py-6 text-center fw-bold">
                            Tiêu chí đánh giá
                          </td>
                          <td className="spaces width-80 px-10 py-6 text-center fw-bold">
                            Điểm đánh giá
                          </td>
                        </tr> 
                      </thead>
                      <tbody>
                        <tr>
                          <td className="spaces pl-10 py-2 fw-bold" colSpan={2}>
                            I. Tổng quan tình hình nghiên cứu và luận giải sự cần thiết của đề tài (điểm tối đa: 15)
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            1. Mức độ đầy đủ, rõ ràng của việc đánh giá, phân tích tình hình nghiên cứu ở
                            trong nước và ngoài nước. Đánh giá mặt mạnh, mặt yếu của các công trình 
                            nghiên cứu đã có và những hạn chế cần giải quyết.
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={5}
                                required
                                readOnly={isView || disabled}
                                name="criterion1"
                                value={valueEvaluation?.criterion1}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>5</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            2. Mức độ rõ ràng, tính khoa học, tính cấp thiết của đề tài. Mức độ rõ ràng của mục tiêu nghiên cứu.
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={10}
                                required
                                readOnly={isView || disabled}
                                name="criterion2"
                                value={valueEvaluation?.criterion2}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>10</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-2 fw-bold" colSpan={2}>
                            II. Nội dung và phương pháp nghiên cứu (Điểm tối đa: 30)
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            3. Tính đầy đủ, phù hợp và logic của nội dung nghiên cứu. Mức độ làm rõ nội dung quan trọng, chủ yếu của đề tài.
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={10}
                                required
                                readOnly={isView || disabled}
                                name="criterion3"
                                value={valueEvaluation?.criterion3}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>10</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            4. Tính đầy đủ, phù hợp, khoa học và logic của phương pháp nghiên cứu tương ứng để đạt mục tiêu của đề tài.
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={15}
                                required
                                readOnly={isView || disabled}
                                name="criterion4"
                                value={valueEvaluation?.criterion4}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>15</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            5. Tiến độ thực hiện đề tài (tính khoa học và hợp lý trong bố trí kế hoạch, các mốc phải đạt, khả năng hoàn thành...)
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={5}
                                required
                                readOnly={isView || disabled}
                                name="criterion5"
                                value={valueEvaluation?.criterion5}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>5</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-2 fw-bold" colSpan={2}>
                            III. Kết quả nghiên cứu dự kiến (điểm tối đa : 25)
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            6. Tính đầy đủ, hợp lý và tương thích về kết quả nghiên cứu so với mục tiêu và nội dung nghiên cứu
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={10}
                                required
                                readOnly={isView || disabled}
                                name="criterion6"
                                value={valueEvaluation?.criterion6}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>10</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            7. Tính khả thi, khả năng ứng dụng kết quả nghiên cứu vào thực tiễn (hoặc ứng dụng công nghệ)
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={15}
                                required
                                readOnly={isView || disabled}
                                name="criterion7"
                                value={valueEvaluation?.criterion7}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>15</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-2 fw-bold" colSpan={2}>
                            IV. Kinh nghiệm NC, kinh phí của đề tài (Tổng điểm tối đa: 30)
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            8. Kinh nghiệm, thành tích nổi bật, năng lực chuyên môn, quản lý của cá nhân đăng ký chủ trì đề tài
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={9}
                                required
                                readOnly={isView || disabled}
                                name="criterion8"
                                value={valueEvaluation?.criterion8}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>9</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            9. Kinh nghiệm nghiên cứu, những thành tích của cán1 bộ tham gia đề tài
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={3}
                                required
                                readOnly={isView || disabled}
                                name="criterion9"
                                value={valueEvaluation?.criterion9}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>3</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            10. Cơ sở vật chất, tiềm lực của cơ quan quản lý đề tài
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={8}
                                required
                                readOnly={isView || disabled}
                                name="criterion10"
                                value={valueEvaluation?.criterion10}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>8</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="spaces pl-10 py-6">
                            11. Tính hợp lý của dự toán kinh phí, tính hợp lý của việc phân bổ kinh phí

                            cho các nội dung nghiên cứu.
                          </td>
                          <td style={{ verticalAlign: "middle" }} className="spaces px-10 text-center">
                            <span className="relative">
                              <input
                                type="number"
                                className="no-padding custom-input no-spinners"
                                min={0}
                                max={10}
                                required
                                readOnly={isView || disabled}
                                name="criterion11"
                                value={valueEvaluation?.criterion11}
                                onChange={(e) => handleChange(e)}
                                // onBlur={(e) => handleBlur(e)}
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              />
                              <span className="dot-line"></span>
                            </span>{""}/<strong>10</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fw-bold">Tổng cộng</td>
                          <td className="text-center fw-bold">
                            {calcSum()}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="mb-4">
                    <div className="spaces min-w-80 fw-bold">Ý kiến nhận xét khác:</div>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        readOnly={isView || disabled}
                        name="opinion"
                        value={valueEvaluation?.opinion}
                        onChange={(e) => handleChange(e)}
                        style={generalTextareaStyle}
                      />
                      {Array.from({
                        length: isNaN(numRows?.opinion)
                          ? 1
                          : numRows?.opinion,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `calc(${(1 / numRows?.opinion || 0) * 100 * index}% - 1px)`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="flex gap-3 w-100 mt-4">
                    {valueEvaluation?.fileId && !openEditFile ? (
                      <>
                        <Form.Group controlId="formFile" className="flex-1">
                          <Form.Control
                            type="text"
                            disabled
                            size="sm"
                            value={valueEvaluation?.originalFileName}
                          />
                        </Form.Group>
                        {!openEditFile && !isView ? (
                          <div>
                            <button
                              type="button"
                              className="spaces h-40 W-40 flex justify-content-center align-items-center btn btn-primary"
                              onClick={() => setOpenEditFile(true)}
                            >
                              <i className="bi bi-pencil-fill p-0"></i>
                            </button>
                          </div>
                        ) : (
                          <Button variant="primary" onClick={() => handleDownloadFile(valueEvaluation?.fileId as string, valueEvaluation?.originalFileName as string)}>Tải xuống</Button>
                        )}
                      </>
                    ) : (
                      <Form.Group controlId="formFile" className="flex-1">
                        <Form.Control
                          disabled={isView || disabled}
                          type="file"
                          name={file?.name}
                          size="sm"
                          accept={".pdf, .doc, .docx"}
                          onChange={(e: any) => handleChangeFile(e)}
                        />
                      </Form.Group>
                    )}
                  </div>
                </Col>
                <div className="d-flex justify-content-between mt-8">
                  <div className="spaces width-54">
                    <p className="fw-bold mb-0">* Đề cương được đề nghị xem xét phê duyệt nếu :</p>
                    <p className="mb-0">{"- Không có 1 tiêu chí nào điểm 0 (tiêu chí : I ;II ;III > 50 điểm)"}</p>
                    <p className="mb-0">- Tổng điểm trung bình từ 70 trở lên, trong đó xếp loại:</p>
                    <p className="spaces mb-0 text-indent-10">Mức A: 86 điểm trở lên</p>
                    <p className="spaces mb-0 text-indent-10">Mức B: từ 76 điểm đến 85 điểm</p>
                    <p className="spaces mb-0 text-indent-10">Mức C: từ 70 điểm đến 75 điểm</p>
                  </div>
                  <div className="spaces width-45 text-center">
                    <p className="center-text fw-bold mt-0">
                      Sóc Sơn, ngày{""}
                      <span className="relative">
                        <input
                          readOnly={isView}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="reviewDay"
                          value={valueEvaluation?.reviewDay}
                          style={{
                            width: "25px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="dot-line"></span>
                      </span>{""}tháng{""}
                      <span className="relative">
                        <input
                          readOnly={isView}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="reviewMonth"
                          value={valueEvaluation?.reviewMonth}
                          style={{
                            width: "25px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="dot-line"></span>
                      </span>{""}năm{""}
                      <span className="relative">
                        <input
                          readOnly={isView}
                          className="no-padding custom-input d-inline-block"
                          type="text"
                          name="reviewYear"
                          value={valueEvaluation?.reviewYear}
                          style={{
                            width: "40px",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="dot-line"></span>
                      </span>
                    </p>
                    <p>
                      <b className="text-uppercase">Người đánh giá</b>
                    </p>
                    <p className="pt-3">{valueEvaluation?.signerName ?? ""}</p>
                    <i>{valueEvaluation?.signed ? "(Đã ký)" : ""}</i>
                    {valueEvaluation?.allowSigning && !valueEvaluation?.signed && (
                      <p className="pt-4">
                        <Button
                          variant="primary"
                          className="min-w-75px fw-bold"
                          type="button"
                          onClick={() => {
                            setOpenConfirmDialog(true);
                          }}
                        >
                          {"Ký"}
                        </Button>
                      </p>
                    )}
                  </div>
                </div>
                {openConfirmDialog && (
                  <ConfirmDialog
                    show={openConfirmDialog}
                    yes="Xác nhận"
                    onYesClick={() => handleYesClick()}
                    message={`Bạn có chắc muốn ký phiếu đánh giá này?`}
                    close="Huỷ"
                    onCloseClick={() => setOpenConfirmDialog(false)}
                    title="Xác nhận thao tác"
                  />
                )}
              </Row>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              {!isView && (
                <Button
                  variant="primary"
                  className="min-w-75px fw-bold"
                  type="submit"
                >
                  {"Lưu"}
                </Button>
              )}
              <Button
                variant="secondary"
                className="min-w-75px fw-bold"
                onClick={onHide}
              >
                Đóng
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default EvaluationForm;
