import axios from "axios";
import { TBatchOfRegistration, TBatchOfRegistrationFilter } from "../models/OpenBatchOfRegistrationModel";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];

export const searchBatchOfRegistration = (data: TBatchOfRegistrationFilter) => {
  let url = `${API_PATH}/regis-periods/search`;
  delete data.yearOption;
  delete data.statusOption;
  return axios.get(url, { params: data });
};
export const addBatchOfRegistration = (data: TBatchOfRegistration) => {
  let url = `${API_PATH}/regis-periods`;
  return axios.post(url, data);
};

export const updateBatchOfRegistration = (data: TBatchOfRegistration) => {
  let url = `${API_PATH}/regis-periods/${data.id}`;
  return axios.put(url, data);
};

export const openBatchOfRegistration = (id: string) => {
  let url = `${API_PATH}/regis-periods/${id}/reopen`;
  return axios.post(url);
};

export const closeBatchOfRegistration = (id: string) => {
  let url = `${API_PATH}/regis-periods/${id}/close`;
  return axios.post(url);
};

export const deleteBatchOfRegistration = (id: string) => {
  let url = `${API_PATH}/regis-periods/${id}`;
  return axios.delete(url);
};