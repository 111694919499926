import axios from "axios";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { localStorageItem } from "../../utils/LocalStorage";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];
const API_XADMIN = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"];

export const searchAssignmentImplement = (params: any) => {
  let url = `${API_PATH}/assignment-decision/search`;
  return axios.get(url, { params });
};

export const deleteAssignmentImplement = (id: string) => {
  let url = `${API_PATH}/assignment-decision/${id}`;
  return axios.delete(url);
};

export const getAssignmentImplementById = (id: string) => {
  let url = `${API_PATH}/assignment-decision/${id}`;
  return axios.get(url);
};


export const checkBatchOfRegistration = (regisPeriodId: string) => {
  let url = `${API_PATH}/assignment-decision/${regisPeriodId}/allow-assignment`;
  return axios.get(url);
}

export const saveAssignmentImplement = (data: any) => {
  let url = `${API_PATH}/assignment-decision`;
  return axios.post(url, data);
}

export const updateAssignmentImplement = (data: any) => {
  let url = `${API_PATH}/assignment-decision`;
  return axios.put(url, data);
}

export const signAssignmentImplement = (id: string) => {
  let url = `${API_PATH}/assignment-decision/${id}/sign`;
  return axios.post(url);
}
