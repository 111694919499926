//@ts-nocheck
import { useRef, useState } from 'react';
import "../../../../../app/modules/styles/index.scss";
import { ROLE } from "../../../../../app/modules/utils/Constant";
import { hasAuthority } from "../../../../../app/modules/utils/Permission";
import { MenuItem } from './MenuItem';
export function MenuInner() {

  const containerRef = useRef(null);
  const [dragStart, setDragStart] = useState(0);
  const [scrollStart, setScrollStart] = useState(0);

  const handleMouseDown = (e: any) => {
    setDragStart(e.clientX);
    setScrollStart(containerRef?.current.scrollLeft);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: any) => {
    const dragDelta = e.clientX - dragStart;
    containerRef.current.scrollLeft = scrollStart - dragDelta;
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center">
        <MenuItem to="/home" icon="./media/icons/menu.svg" />
        {/* <i className="bi bi-chevron-bar-left fs-2 cursor-pointer" onClick={scrollToLeft}></i> */}
        <div
          className="header-menu-container"
          ref={containerRef}
          onMouseDown={handleMouseDown}
        >
          {hasAuthority(ROLE.PHONG_NCKH) && (
            <MenuItem to="quan-ly-de-tai/mo-dot-dang-ky" title={"Mở đợt đăng ký"} />
          )}
          <MenuItem to="/dang-ky-de-tai" title={"Đăng ký đề tài"} />
          <MenuItem to="/de-tai-cua-toi" title={"Đề tài của tôi"} />
          <MenuItem to="quan-ly-de-tai/de-tai" title={"Danh sách đề tài"} />
          {hasAuthority(ROLE.PHONG_NCKH) && (
            <>
              <MenuItem
                to="/quan-ly-hoi-dong/khoa-hoc"
                title={"Hội đồng khoa học"}
              />
              <MenuItem
                to="/quan-ly-hoi-dong/nghiem-thu"
                title={"Hội đồng nghiệm thu"}
              />
            </>
          )}
          {(hasAuthority(ROLE.PHONG_NCKH) || hasAuthority(ROLE.GIAM_DOC)) && (
            <MenuItem to="quyet-dinh-giao-thuc-hien" title={"Quyết định giao thực hiện"} />
          )}
          {(hasAuthority(ROLE.PHONG_NCKH) || hasAuthority(ROLE.GIAM_DOC)) && (
            <MenuItem to="quyet-dinh-cong-nhan" title={"Quyết định công nhận"} />
          )}
        </div>
      </div>
      {/* <i className="bi bi-chevron-bar-right fs-2 cursor-pointer" onClick={scrollToRight}></i> */}
    </div>
  );
}