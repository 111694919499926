import React from 'react'
import AutocompleteV2 from '../../component/AutocompleteObjectV2';
import { useFormikContext } from 'formik';
import { searchBatchOfRegistration } from '../../dang-ky-de-tai/services/services';
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from '../../utils/Constant';
import moment from 'moment';

type Props = {
    isView?: boolean
}

const Step1 = ({ isView }: Props) => {
    const { values, setValues, errors, touched } = useFormikContext<any>();
  return (
      <div className="flex align-items-center w-100">
          <div className="flex-1">
              <AutocompleteV2
                  options={[]}
                  isSearchDefauilt={true}
                  name={"regisPeriod"}
                  onChange={(option: any) => {
                      setValues({
                          ...values,
                          regisPeriod: option,
                          code: `BVĐKSS.NCKH.${moment(option?.startDate).year()}.11`,
                          regisPeriodYear: moment(option?.startDate).year(),
                      })
                  }}
                  value={values?.regisPeriod}
                  errors={errors?.regisPeriod}
                  touched={touched?.regisPeriod}
                  searchFunction={searchBatchOfRegistration}
                  searchObject={{
                      pageIndex: DEFAULT_PAGE_INDEX,
                      pageSize: MAX_PAGE_SIZE
                  }}
                  getOptionLabel={(e: any) => e?.name}
                  placeholder='-- Chọn đợt đăng ký --'
                  menuPlacement='bottom'
                  isDisabled={isView || values?.id}
              />
          </div>
      </div>
  )
}

export default Step1