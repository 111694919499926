import axios from "axios";
import { TMyTopicFilter } from "../models/MyTopicModel";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];

export const searchTopic = (data: TMyTopicFilter) => {
  let url = `${API_PATH}/topics/mine`;
  delete data.yearOption;
  return axios.get(url, { params: data });
};

export const addTopicOutline = (data: any, id: string) => {
  let url = `${API_PATH}/topics/${id}/outline`;
  return axios.post(url, data);
};

export const updateTopicOutline = (data: any, id: string) => {
  let url = `${API_PATH}/topics/${id}/outline`;
  return axios.put(url, data);
};

export const getTopicById = (id: string) => {
  let url = `${API_PATH}/topics/${id}`;
  return axios.get(url);
};

export const addReportProduct = (data: any) => {
  let url = `${API_PATH}/topics/report`;
  axios.defaults.timeout = 0;
  return axios.post(url, data);
};

export const getAttachmentList = (data: { topicId: string, typeId: string }) => {
  let url = `${API_PATH}/files`;
  return axios.get(url, { params: data });
};

export const deleteTopicById = (id: string) => {
  let url = `${API_PATH}/topics/${id}`;
  return axios.delete(url);
};

export const getAttachmentProduct = (data: { topicId: string }) => {
  let url = `${API_PATH}/topics/report`;
  return axios.get(url, { params: data });
};

export const getTopicScienceMinutes = (id: string) => {
  let url = `${API_PATH}/topics/${id}/science-record`;
  return axios.get(url);
};

export const getTopicAcceptanceMinutes = (id: string) => {
  let url = `${API_PATH}/topics/${id}/acceptance-record`;
  return axios.get(url);
};

export const getReviewScienceList = (topicId: string) => {
  let url = `${API_PATH}/topic-review/science/by-topic?topicId=${topicId}`;
  return axios.get(url);
};

export const getReviewAcceptanceList = (topicId: string) => {
  let url = `${API_PATH}/topic-review/acceptance/by-topic?topicId=${topicId}`;
  return axios.get(url);
};

export const chairmanSignScience = (recordId: string) => {
  let url = `${API_PATH}/topics/sign-record/science/chairman?recordId=${recordId}`;
  return axios.post(url);
};

export const chairmanSignAcceptance = (recordId: string) => {
  let url = `${API_PATH}/topics/sign-record/acceptance/chairman?recordId=${recordId}`;
  return axios.post(url);
};

export const secretarySignScience = (recordId: string) => {
  let url = `${API_PATH}/topics/sign-record/science/secretary?recordId=${recordId}`;
  return axios.post(url);
};

export const secretarySignAcceptance = (recordId: string) => {
  let url = `${API_PATH}/topics/sign-record/acceptance/secretary?recordId=${recordId}`;
  return axios.post(url);
};

export const getOulineFile = (topicId: string) => {
  let url = `${API_PATH}/files/outline?topicId=${topicId}`;
  return axios.get(url);
};