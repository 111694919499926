import axios from "axios";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { localStorageItem } from "../../utils/LocalStorage";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];

export const searchTopicRecognition = (params: any) => {
  let url = `${API_PATH}/recognition-decision/search`;
  return axios.get(url, { params });
};

export const deleteTopicRecognition = (id: string) => {
  let url = `${API_PATH}/recognition-decision/${id}`;
  return axios.delete(url);
};

export const getTopicRecognitionById = (id: string) => {
  let url = `${API_PATH}/recognition-decision/${id}`;
  return axios.get(url);
};

export const getTopicRecognitionInfo = (data: { regisPeriodId: string, topics: any[] }) => {
  let url = `${API_PATH}/recognition-decision/regis-period/info`;
  return axios.post(url, data);
};

export const checkBatchOfRegistration = (regisPeriodId: string) => {
  let url = `${API_PATH}/recognition-decision/${regisPeriodId}/allow-recognize`;
  return axios.get(url);
}

export const saveTopicRecognition = (data: any) => {
  let url = `${API_PATH}/recognition-decision`;
  return axios.post(url, data);
}

export const updateTopicRecognition = (data: any) => {
  let url = `${API_PATH}/recognition-decision`;
  return axios.put(url, data);
}

export const signTopicRecognition = (id: string) => {
  let url = `${API_PATH}/recognition-decision/${id}/sign`;
  return axios.post(url);
}
