import axios from "axios";
import { TScienceCouncilFilter } from "../models/ScienceCouncilManagerModels";
import { TBatchOfRegistrationFilter } from "../../../dang-ky-de-tai/models/TopicRegistrationModel";
import { TMyTopicFilter } from "../../../de-tai-cua-toi/models/MyTopicModel";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];
const API_PATH_EMPLOYEE = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["hrmApiUrl"] ?? process.env.REACT_APP_EMPLOYEE_API_URL;

export const searchScienceCouncil = (data: TScienceCouncilFilter) => {
  let url = `${API_PATH}/committees/search`;
  delete data.yearOption;
  return axios.get(url, { params: data });
};

export const addScienceCouncil = (data: any) => {
  let url = `${API_PATH}/committees`;
  const config = {
  headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const updateScienceCouncil = (data: any, id: string) => {
  let url = `${API_PATH}/committees/${id}`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.put(url, data, config);
};

export const searchBatchOfRegistration = (data: TBatchOfRegistrationFilter) => {
  let url = `${API_PATH}/regis-periods/search`;
  return axios.get(url, { params: data });
};

export const getSummaryInfo = (data: TScienceCouncilFilter) => {
  let url = `${API_PATH}/topics/examination-info`;
  return axios.get(url, { params: data });
};

export const deleteScienceCouncilById = (id: string) => {
  let url = `${API_PATH}/committees/${id}`;
  return axios.delete(url);
};

export const getScienceCouncilById = (id: string) => {
  let url = `${API_PATH}/committees/${id}`;
  return axios.get(url);
};

export const searchTopic = (data: TMyTopicFilter) => {
  let url = `${API_PATH}/topics/pending-examination`;
  delete data.yearOption;
  return axios.get(url, { params: data });
};

export const searchEmployee = (data: any) => {
  let url = `${API_PATH_EMPLOYEE}/employee/page/v2`;
  return axios.get(url, { params: data });
}