import { useFormikContext } from 'formik';
import { ConfirmDialog } from '../../component/ConfirmDialog';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CODE, RESPONSE_MESSAGE, TRANG_THAI } from '../../utils/Constant';
import { signAssignmentImplement } from '../services/services';
import { searchTopic } from '../../quan-ly-de-tai/de-tai/services/services';

type Props = {
    isView?: boolean
    onRefresh?: () => void
    topicList: any[]
    setTopicList: React.Dispatch<any>
}

const Step2 = ({ isView, onRefresh, topicList, setTopicList }: Props) => {

    const {values, setFieldValue} = useFormikContext<any>();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [signInfo, setSignInfo] = useState<any>(null);

    const handleChange = (e: any) => {
        const { name, value, type } = e.target;
        setFieldValue(name, type === "number" ? Number(value) : value );
    };

    const handleYesClick = async () => {
        try {
            let { data } = await signAssignmentImplement(signInfo?.id);
            if (data?.code === CODE.SUCCESS) {
                setSignInfo({
                    ...signInfo,
                    signed: true
                });
                onRefresh?.();
                setOpenConfirmDialog(false);
                toast.success(`Ký quyết định thành công!`);
            } else {
                toast.error(RESPONSE_MESSAGE.ERROR);
                setOpenConfirmDialog(false);
            }
        } catch (error) {
            console.error(error);
            setOpenConfirmDialog(false);
        }
    }

    const handleGetTopicList = async () => {
        try {
            let { data } = await searchTopic({
                year: values?.regisPeriodYear,
                statusId: String(TRANG_THAI.CHO_GIAO_THUC_HIEN),
            });
            if (data?.code === CODE.SUCCESS) {
                setTopicList(data?.data?.content);
            } else {
                toast.error("Đã có lỗi xảy ra!");
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        !values?.id && values?.regisPeriodYear && handleGetTopicList();
    }, [values?.regisPeriodYear]);

    useEffect(() => {
        setSignInfo(values);
    }, [values?.canSign]);

    return (
        <div className="a4-page" id="assignment-implement-form">
            <div className="a4-container spaces px-40 py-20 bg-white">
                <div className="d-flex justify-content-between w-100 mb-n6 mt-3">
                    <em className="underline">Quy trình đăng ký, phê duyệt đề tài NCKH cấp cơ sở</em>
                    <b className="border border-dark spaces px-5">{values?.code ?? ""}</b>
                </div>
                <div className="header-container">
                    <div className="left-column center-text">
                        <h3 className="bold">SỞ Y TẾ HÀ NỘI</h3>
                        <h3 className="bold underline">
                            BỆNH VIỆN ĐA KHOA SÓC SƠN{" "}
                        </h3>
                        <p className="center-text">
                            Số:{""}
                            <span className="relative">
                                <input
                                    readOnly={isView}
                                    className="no-padding custom-input"
                                    type="text"
                                    name="decisionNumber"
                                    value={values?.decisionNumber}
                                    style={{
                                        width: "60px",
                                        textAlign: "center",
                                        display: "inline-block",
                                    }}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                <span className="dot-line"></span>
                            </span>{""}
                            /QĐ-BVĐKSS
                        </p>
                    </div>
                    <div className="right-column center-text">
                        <p className="bold spaces mb-4">
                            CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
                        </p>
                        <p className="underline bold">
                            Độc lập – Tự do – Hạnh phúc
                        </p>
                        <p className="center-text">
                            Sóc Sơn, ngày{""}
                            <span className="relative">
                                <input
                                    readOnly={isView}
                                    className="no-padding custom-input"
                                    type="text"
                                    name="issueDay"
                                    value={values?.issueDay}
                                    style={{
                                        width: "25px",
                                        textAlign: "center",
                                        display: "inline-block",
                                    }}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                <span className="dot-line"></span>
                            </span>{""}tháng{""}
                            <span className="relative">
                                <input
                                    readOnly={isView}
                                    className="no-padding custom-input"
                                    type="text"
                                    name="issueMonth"
                                    value={values?.issueMonth}
                                    style={{
                                        width: "25px",
                                        textAlign: "center",
                                        display: "inline-block",
                                    }}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                <span className="dot-line"></span>
                            </span>{""}năm{""}
                            <span className="relative">
                                <input
                                    readOnly={isView}
                                    className="no-padding custom-input"
                                    type="text"
                                    name="issueYear"
                                    value={values?.issueYear}
                                    style={{
                                        width: "40px",
                                        textAlign: "center",
                                        display: "inline-block",
                                    }}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                <span className="dot-line"></span>
                            </span>
                        </p>
                    </div>
                </div>

                <h2 className="text-center bold section">
                    QUYẾT ĐỊNH
                </h2>
                <h4 className="text-center">
                    Về việc giao thực hiện đề tài nghiên cứu khoa học cấp cơ sở năm {values?.regisPeriodYear ?? ""}
                </h4>
                <div className="w-100 flex-center">
                    <p className="border border-bottom border-top-0 border-dark spaces width-30 mb-0"></p>
                </div>
                <h2 className="text-center bold section mt-2">GIÁM ĐỐC BỆNH VIỆN ĐA KHOA SÓC SƠN</h2>

                <div className="section spaces px-20">
                    <p className="spaces text-indent-30">
                        Căn cứ Quyết định số 29/2016/QĐ-UBND ngày 19/8/2016 của UBND Thành phố Hà Nội về việc ban hành Quy chế quản lý các nhiệm vụ khoa học và công nghệ của thành phố Hà Nội;
                    </p>
                    <p className="spaces text-indent-30">
                        Căn cứ Quyết định số 2069/QĐ-SYT ngày 27/10/2017 của Giám đốc Sở Y tế Hà Nội về việc ban hành quy định phân cấp quản lý tổ chức bộ máy công chức, viên chức, người lao động trong các đơn vị thuộc Sở Y tế Hà Nội;
                    </p>
                    <p className="spaces text-indent-30">
                        Căn cứ công văn số 875/SYT-NVY ngày 24/3/2017 của Sở Y tế Hà Nội về việc Hướng dẫn quản lý hoạt động nghiên cứu khoa học cho các đơn vị trực thuộc Sở Y tế;
                    </p>
                    <p className="spaces text-indent-30">
                        Căn cứ các kết quả Hội đồng khoa học – Công nghệ xét duyệt đề cương nghiên cứu khoa học cấp cơ sở năm {values?.regisPeriodYear ?? ""};
                    </p>
                    <p className="spaces text-indent-30">Xét đề nghị của Trưởng phòng Kế hoạch tổng hợp – Bệnh viện đa khoa Sóc Sơn;</p>
                    <h2 className="text-center bold section">
                        QUYẾT ĐỊNH:
                    </h2>

                    <p>
                        <b>Điều 1.</b> Giao nhiệm vụ cho các chủ nhiệm và thư ký đề tài thực hiện đề tài nghiên cứu khoa học cấp cơ sở năm {values?.regisPeriodYear ?? ""}
                        (Có phụ lục kèm theo).
                    </p>
                    <p className="spaces text-indent-20">
                        - Thời gian thực hiện:  Năm{""}
                        <span className="relative">
                            <input
                                readOnly={isView}
                                className="no-padding custom-input"
                                type="text"
                                name="implementationYear"
                                value={values?.implementationYear}
                                style={{
                                    width: "40px",
                                    textAlign: "center",
                                    display: "inline-block",
                                }}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                            />
                            <span className="dot-line"></span>
                        </span>
                    </p>
                    <p className="spaces text-indent-20">
                        - Địa điểm:	Bệnh viện đa khoa Sóc Sơn
                    </p>
                    <p>
                        <b>Điều 2.</b> Các chủ nhiệm và thư ký đề tài được giao thực hiện đúng kế hoạch, nội dung đã được Hội đồng khoa học – Công nghệ Bệnh viện phê duyệt trong đề cương.
                    </p>
                    <p>
                        <b>Điều 3.</b> Giao phòng Kế hoạch tổng hợp, Phòng Tổ chức cán bộ, Phòng Tài chính – Kế toán, các đơn vị và cá nhân có liên quan tổ chức triển khai thực hiện theo đúng các quy định hiện hành của Bệnh viện, Sở y tế Hà Nội. Quyết định này có hiệu lực kể từ ngày ký, ban hành./.
                    </p>

                </div>

                <div className="signature-container">
                    <div className="spaces ml-20">
                        <p className="fw-bold mb-0">Nơi nhận:</p>
                        <p className="mb-0">- Ban giám đốc (đề b/c)</p>
                        <p className="mb-0">- Như điều 3;</p>
                        <p className="mb-0">- Lưu: VT, CĐT</p>
                    </div>

                    <div className="right-signature">
                        <p>
                            <b className="text-uppercase">Giám đốc</b>
                        </p>
                        <p className="pt-3">{signInfo?.signer ?? ""}</p>
                        <i>{signInfo?.signed ? "(Đã ký)" : ""}</i>
                        {signInfo?.canSign && !signInfo?.signed && (
                            <p className="pt-4">
                                <Button
                                    variant="primary"
                                    className="min-w-75px fw-bold"
                                    type="button"
                                    onClick={() => {
                                        setOpenConfirmDialog(true);
                                    }}
                                >
                                    {"Ký"}
                                </Button>
                            </p>
                        )}
                    </div>
                </div>
            </div>

            <div className="a4-container spaces px-30 py-20 bg-white mt-15 min-h-912">
                <div className="d-flex justify-content-between w-100 spaces mt-10 px-20">
                    <em className="underline">Quy trình đăng ký, phê duyệt đề tài NCKH cấp cơ sở</em>
                    <b className="border border-dark spaces px-5">{values?.code ?? ""}</b>
                </div>

                <h2 className="text-center bold section mt-3">DANH SÁCH ĐỀ TÀI NCKH CẤP CƠ SỞ NĂM {values?.regisPeriodYear ?? ""}</h2>

                <div className='d-flex justify-content-center w-100'>
                    <em className='text-center spaces width-65'>
                        (Kèm theo Quyết định số	{values?.decisionNumber ?? ""}/QĐ-BVĐKSS 	ngày {values?.issueDay ?? ""} tháng {values?.issueMonth ?? ""} năm {values?.issueYear ?? ""} của Giám đốc Bệnh viện đa khoa Sóc Sơn)
                    </em>
                </div>

                <div className="section spaces">
                    <table className='table-ds-de-tai'>
                        <thead>
                            <tr>
                                <th className="text-center">TT</th>
                                <th className="text-center">Mã số</th>
                                <th className="text-center">Khoa</th>
                                <th className="text-center">Tên đề tài</th>
                                <th className="text-center">Chủ nhiệm ĐT</th>
                                <th className="text-center">Thư ký ĐT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topicList?.map((item: any, index: number) => (
                                <tr key={index + ""}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">{item?.code ?? "..."}</td>
                                    <td>{item?.principalInvestigatorDepartment ?? "..."}</td>
                                    <td>{item?.name ?? "..."}</td>
                                    <td>{item?.principalInvestigatorName ?? "..."}</td>
                                    <td>{item?.secretaryName ?? "..."}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {openConfirmDialog && (
                <ConfirmDialog
                    show={openConfirmDialog}
                    yes="Xác nhận"
                    onYesClick={() => handleYesClick()}
                    message={`Bạn có chắc muốn ký quyết định này?`}
                    close="Huỷ"
                    onCloseClick={() => setOpenConfirmDialog(false)}
                    title="Xác nhận thao tác"
                />
            )}
        </div>
    )
}

export default Step2