import moment from "moment";

const currentYear = moment().year();

// Tìm kiếm
export type TBatchOfRegistrationFilter = {
  year?: string;
  yearOption?: any;
  status?: string;
  keyword?: string;
  statusOption?: any;
  pageIndex?: number;
  pageSize?: number;
};

export const initFilter: TBatchOfRegistrationFilter = {
  year: currentYear.toString(),
  status: "all",
  keyword: "",
  pageIndex: 1,
  pageSize: 10
};

