import { Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import React, { FC, useContext } from "react";
import { Col, Form as FormBS, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { TextFieldCustom } from "../../category-personnel/components/text-field-custom/TextFieldCustom";
import AutocompleteObjectV2 from '../../component/AutocompleteObjectV2';
import { searchEmployee } from "../../quan-ly-hoi-dong/khoa-hoc/services/services";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, MEMBER_TYPE } from "../../utils/Constant";
import { initProposal, TBatchOfRegistration, TMember, TProposal } from "../models/TopicRegistrationModel";
import { addProposal } from "../services/services";
import { TopicRegistrationContext } from "../TopicRegistrationContext";

type Props = {
  show: boolean;
  onHide: () => void;
  batchOfRegistrationInfo: TBatchOfRegistration;
  onRefresh: () => Promise<void>;
};

const AddProposalDialog: FC<Props> = (props) => {
  const { show, onHide, batchOfRegistrationInfo, onRefresh } = props;

  const { onReset } = useContext(TopicRegistrationContext);
  const { setIsLoading } = useContext(AppContext);

  const handleCheckedChange = (checked: boolean, values: TProposal, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, name: string) => {
    if(checked === true){
      let newParticipatingMembers = values?.participatingMembers;
      newParticipatingMembers?.pop();
      setFieldValue("participatingMembers", newParticipatingMembers);
    }else if(checked === false){
      let newParticipatingMembers = values?.participatingMembers;
      newParticipatingMembers?.push({});
      setFieldValue("participatingMembers", newParticipatingMembers);
      setFieldValue(name, {});
    }
  }

  const handleFormSubmit = async (values: TProposal) => {
    let participatingMembers = values?.participatingMembers?.map((member: TMember, index: number) => ({
      ...member,
      typeId: MEMBER_TYPE.CAN_BO_THUC_HIEN
    })) || [];
    let members = [
      { ...values.mainResearcher, typeId: MEMBER_TYPE.CHU_NHIEM_DE_TAI },
      ...participatingMembers,
      ...(values?.hasCoMainResearcher ? [{...values.coMainResearcher, typeId: MEMBER_TYPE.DONG_CHU_NHIEM}] : []),
      ...(values?.hasTopicSecretary ? [{...values.topicSecretary, typeId: MEMBER_TYPE.THU_KY}] : []),
    ];
    let dataProposal = {
      regisPeriodId: batchOfRegistrationInfo.id,
      name: values.name,
      date: values.date,
      urgency: values.urgency,
      objective: values.objective,
      content: values.content,
      estimatedTimeframe: values.estimatedTimeframe,
      expectedOutcome: values.expectedOutcome,
      feasibility: values.feasibility,
      otherInformation: values.otherInformation,
      members
    }
    try {
      setIsLoading(true);
      let { data } = await addProposal(dataProposal);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success("Thêm đề xuất thành công");
        onHide();
        onRefresh();
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik<TProposal>
        enableReinitialize
        initialValues={{ ...initProposal }}
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Header className="header-modal min-h-40px">
                <div className="d-flex gap-6 py-2">
                  <div
                    className="d-flex align-items-center title-back gap-3 cursor-pointer"
                    onClick={() => {
                      onReset();
                      onHide();
                    }}
                  >
                    <i className="spaces bi bi-chevron-left ml-10"> </i>
                    <div className="spaces mr-20">Quay lại</div>
                  </div>
                  <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
                    Thêm mới phiếu đề xuất
                  </h4>
                </div>
              </Modal.Header>
              <Modal.Body className="spaces p-0 mt-5 bg-white">
                <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
                  {`Phiếu đề xuất đề tài cấp cơ sở năm ${moment(batchOfRegistrationInfo?.startDate ?? new Date())?.year() ?? ""}`}
                </h4>
                <div className="form spaces p-10">
                  <Row className="py-2">
                    <Col xs={8}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Tên đề tài
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="name"
                            values={values.name}
                            errors={errors.name}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Ngày đăng ký
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="date"
                            title=""
                            name="date"
                            values={values.date}
                            errors={errors.date}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col
                      xs={12}
                      className="label min-w-100px fw-bold pb-2 text-gray"
                    >
                      Chủ nhiệm đề tài/Nghiên cứu viên chính:
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center px-2">
                        <div className="label min-w-100px fw-bold">
                          Họ tên
                        </div>
                        <div className="flex-1">
                          <AutocompleteObjectV2
                            options={[]}
                            name="mainResearcher"
                            onChange={(option: any) => {
                              setFieldValue("mainResearcher",
                                {
                                  ...option,
                                  gender: option?.gender?.code,
                                  duty: option?.viTriCongViecText,
                                  department: option?.phongBanText,
                                  position: option?.chucVuText,
                                  email: option?.emailCaNhan,
                                }
                              )
                            }}
                            searchFunction={() =>
                              searchEmployee({
                                pageIndex: DEFAULT_PAGE_INDEX,
                                pageSize: MAX_PAGE_SIZE,
                                donViCongTacId: "8e482f21-2414-422a-9bc1-fb997776bc2b"
                              })
                            }
                            value={values?.mainResearcher}
                            errors={errors?.mainResearcher}
                            searchObject={{}}
                            getOptionLabel={(e: any) => e?.name}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Khoa phòng
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            disabled
                            name="mainResearcher.department"
                            values={values.mainResearcher?.department ?? ""}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Chức vụ
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            disabled
                            name="mainResearcher.position"
                            values={values.mainResearcher?.position ?? ""}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center px-2 pt-6">
                        <div className="label min-w-100px fw-bold">
                          Điện thoại
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            disabled
                            name="mainResearcher.phone"
                            values={values.mainResearcher?.phone ?? ""}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center pt-6">
                        <div className="label min-w-100px fw-bold">Email</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            disabled
                            name="mainResearcher.email"
                            values={values.mainResearcher?.email ?? ""}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col xs={12}>
                      <div className="flex align-items-center">
                        <div className="label fw-bold text-pri spaces pr-16">
                          <Field
                            type="checkbox"
                            name="hasCoMainResearcher"
                            checked={values.hasCoMainResearcher}
                            onChange={(e: any) => {
                              handleChange(e);
                              handleCheckedChange(e?.target?.checked, values, setFieldValue, "coMainResearcher");
                            }}
                          />
                        </div>
                        <div className="flex-1">
                          <FormBS.Label className="label fw-bold text-gray">
                            Đồng chủ nhiệm
                          </FormBS.Label>
                        </div>
                      </div>
                    </Col>
                    {values.hasCoMainResearcher && (
                      <>
                        <Col xs={4}>
                          <div className="flex align-items-center px-2">
                            <div className="label min-w-100px fw-bold">
                              Họ tên
                            </div>
                            <div className="flex-1">
                              <AutocompleteObjectV2
                                options={[]}
                                name="coMainResearcher"
                                onChange={(option: any) => {
                                  setFieldValue("coMainResearcher",
                                    {
                                      ...option,
                                      gender: option?.gender?.code,
                                      duty: option?.viTriCongViecText,
                                      department: option?.phongBanText,
                                      position: option?.chucVuText,
                                      email: option?.emailCaNhan,
                                    }
                                  )
                                }}
                                searchFunction={() =>
                                  searchEmployee({
                                    pageIndex: DEFAULT_PAGE_INDEX,
                                    pageSize: MAX_PAGE_SIZE,
                                    donViCongTacId: "8e482f21-2414-422a-9bc1-fb997776bc2b"
                                  })
                                }
                                value={values?.coMainResearcher}
                                errors={errors?.coMainResearcher}
                                searchObject={{}}
                                getOptionLabel={(e: any) => e?.name}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="flex align-items-center">
                            <div className="label min-w-100px fw-bold">
                              Khoa phòng
                            </div>
                            <div className="flex-1">
                              <TextFieldCustom
                                type="text"
                                title=""
                                disabled
                                name="coMainResearcher.department"
                                values={values.coMainResearcher?.department ?? ""}
                                errors={errors.coMainResearcher}
                                handleChange={handleChange}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="flex align-items-center">
                            <div className="label min-w-100px fw-bold">
                              Chức vụ
                            </div>
                            <div className="flex-1">
                              <TextFieldCustom
                                type="text"
                                title=""
                                disabled
                                name="coMainResearcher.position"
                                values={values.coMainResearcher?.position ?? ""}
                                errors={errors.coMainResearcher}
                                handleChange={handleChange}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="flex align-items-center px-2 pt-6">
                            <div className="label min-w-100px fw-bold">
                              Điện thoại
                            </div>
                            <div className="flex-1">
                              <TextFieldCustom
                                type="text"
                                title=""
                                disabled
                                name="coMainResearcher.phone"
                                values={values.coMainResearcher?.phone ?? ""}
                                errors={errors.coMainResearcher}
                                handleChange={handleChange}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="flex align-items-center pt-6">
                            <div className="label min-w-100px fw-bold">Email</div>
                            <div className="flex-1">
                              <TextFieldCustom
                                type="text"
                                title=""
                                disabled
                                name="coMainResearcher.email"
                                values={values.coMainResearcher?.email ?? ""}
                                errors={errors.coMainResearcher}
                                handleChange={handleChange}
                              />
                            </div>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row className="py-2">
                    <Col xs={12}>
                      <div className="flex align-items-center">
                        <div className="label fw-bold text-pri spaces pr-16">
                          <Field
                            type="checkbox"
                            name="hasTopicSecretary"
                            checked={values.hasTopicSecretary}
                            onChange={(e: any) => {
                              handleChange(e);
                              handleCheckedChange(e?.target?.checked, values, setFieldValue, "topicSecretary");
                            }}
                          />
                        </div>
                        <div className="flex-1">
                          <FormBS.Label>
                            <span className="label min-w-100px fw-bold text-gray">
                              Thư ký đề tài
                            </span>
                            <span className="fst-italic fw-light text-gray">
                              {" "}
                              (tham gia với &gt;50% thời gian, nếu có):
                            </span>
                          </FormBS.Label>
                        </div>
                      </div>
                    </Col>
                    {values.hasTopicSecretary && (
                      <>
                        <Col xs={4}>
                          <div className="flex align-items-center px-2">
                            <div className="label min-w-100px fw-bold">
                              Họ tên
                            </div>
                            <div className="flex-1">
                              <AutocompleteObjectV2
                                options={[]}
                                name="topicSecretary"
                                onChange={(option: any) => {
                                  setFieldValue("topicSecretary",
                                    {
                                      ...option,
                                      gender: option?.gender?.code,
                                      duty: option?.viTriCongViecText,
                                      department: option?.phongBanText,
                                      position: option?.chucVuText,
                                      email: option?.emailCaNhan,
                                    }
                                  )
                                }}
                                searchFunction={() =>
                                  searchEmployee({
                                    pageIndex: DEFAULT_PAGE_INDEX,
                                    pageSize: MAX_PAGE_SIZE,
                                    donViCongTacId: "8e482f21-2414-422a-9bc1-fb997776bc2b"
                                  })
                                }
                                value={values?.topicSecretary}
                                errors={errors?.topicSecretary}
                                searchObject={{}}
                                getOptionLabel={(e: any) => e?.name}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="flex align-items-center">
                            <div className="label min-w-100px fw-bold">
                              Khoa phòng
                            </div>
                            <div className="flex-1">
                              <TextFieldCustom
                                type="text"
                                title=""
                                disabled
                                name="topicSecretary.department"
                                values={values.topicSecretary?.department ?? ""}
                                errors={errors.topicSecretary}
                                handleChange={handleChange}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="flex align-items-center">
                            <div className="label min-w-100px fw-bold">
                              Chức vụ
                            </div>
                            <div className="flex-1">
                              <TextFieldCustom
                                type="text"
                                title=""
                                disabled
                                name="topicSecretary.position"
                                values={values.topicSecretary?.position ?? ""}
                                errors={errors.topicSecretary}
                                handleChange={handleChange}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="flex align-items-center px-2 pt-6">
                            <div className="label min-w-100px fw-bold">
                              Điện thoại
                            </div>
                            <div className="flex-1">
                              <TextFieldCustom
                                type="text"
                                title=""
                                disabled
                                name="topicSecretary.phone"
                                values={values.topicSecretary?.phone ?? ""}
                                errors={errors.topicSecretary}
                                handleChange={handleChange}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="flex align-items-center pt-6">
                            <div className="label min-w-100px fw-bold">Email</div>
                            <div className="flex-1">
                              <TextFieldCustom
                                type="text"
                                title=""
                                disabled
                                name="topicSecretary.email"
                                values={values.topicSecretary?.email ?? ""}
                                errors={errors.topicSecretary}
                                handleChange={handleChange}
                              />
                            </div>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row className="py-2">
                    <FieldArray
                      name="participatingMembers"
                      render={() => (
                        <>
                          {values?.participatingMembers?.map((item, index) => (
                            <React.Fragment key={index + ""}>
                              <Col
                                xs={12}
                                className="label min-w-100px fw-bold pb-2 text-gray"
                              >
                                <div className="flex justify-content-between align-items-center">
                                  <p className="m-0">Thành viên tham gia {index + 1}</p>
                                </div>
                              </Col>
                              <Col xs={4}>
                                <div className="flex align-items-center px-2">
                                  <div className="label min-w-100px fw-bold">
                                    Họ tên
                                  </div>
                                  <div className="flex-1">
                                    <AutocompleteObjectV2
                                      options={[]}
                                      name={`participatingMembers.${index}`}
                                      onChange={(option: any) => {
                                        setFieldValue(`participatingMembers.${index}`,
                                          {
                                            ...option,
                                            gender: option?.gender?.code,
                                            duty: option?.viTriCongViecText,
                                            department: option?.phongBanText,
                                            position: option?.chucVuText,
                                            email: option?.emailCaNhan,
                                          }
                                        )
                                      }}
                                      searchFunction={() =>
                                        searchEmployee({
                                          pageIndex: DEFAULT_PAGE_INDEX,
                                          pageSize: MAX_PAGE_SIZE,
                                          donViCongTacId: "8e482f21-2414-422a-9bc1-fb997776bc2b"
                                        })
                                      }
                                      value={values?.participatingMembers?.[index]}
                                      errors={errors?.participatingMembers?.[index]}
                                      searchObject={{}}
                                      getOptionLabel={(e: any) => e?.name}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col xs={4}>
                                <div className="flex align-items-center">
                                  <div className="label min-w-100px fw-bold">
                                    Khoa phòng
                                  </div>
                                  <div className="flex-1">
                                    <TextFieldCustom
                                      type="text"
                                      title=""
                                      disabled
                                      name={`participatingMembers.${index}.department`}
                                      values={values.participatingMembers?.[index]?.department ?? ""}
                                      errors={errors.participatingMembers?.[index]}
                                      handleChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col xs={4}>
                                <div className="flex align-items-center">
                                  <div className="label min-w-100px fw-bold">
                                    Chức vụ
                                  </div>
                                  <div className="flex-1">
                                    <TextFieldCustom
                                      type="text"
                                      title=""
                                      disabled
                                      name={`participatingMembers.${index}.position`}
                                      values={values.participatingMembers?.[index]?.position ?? ""}
                                      errors={errors.participatingMembers?.[index]}
                                      handleChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col xs={4}>
                                <div className="flex align-items-center px-2 pt-6">
                                  <div className="label min-w-100px fw-bold">
                                    Điện thoại
                                  </div>
                                  <div className="flex-1">
                                    <TextFieldCustom
                                      type="text"
                                      title=""
                                      disabled
                                      name={`participatingMembers.${index}.phone`}
                                      values={values.participatingMembers?.[index]?.phone ?? ""}
                                      errors={errors.participatingMembers?.[index]}
                                      handleChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col xs={4}>
                                <div className="flex align-items-center pt-6">
                                  <div className="label min-w-100px fw-bold">Email</div>
                                  <div className="flex-1">
                                    <TextFieldCustom
                                      type="text"
                                      title=""
                                      disabled
                                      name={`participatingMembers.${index}.email`}
                                      values={values.participatingMembers?.[index]?.email ?? ""}
                                      errors={errors.participatingMembers?.[index]}
                                      handleChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        1. Giải thích về tính cấp thiết của đề tài
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (Nêu tầm quan trọng, bức xúc, cấp bách)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="urgency"
                      values={values.urgency}
                      errors={errors.urgency}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        2. Mục tiêu dự kiến
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="objective"
                      values={values.objective}
                      errors={errors.objective}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        3. Nội dung
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (Trình bày phương pháp tiếp cận, thiết kế nghiên cứu
                        và nội dung nghiên cứu)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="content"
                      values={values.content}
                      errors={errors.content}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        4. Ước tính thời gian nghiên cứu
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="estimatedTimeframe"
                      values={values.estimatedTimeframe}
                      errors={errors.estimatedTimeframe}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        5. Dự kiến sản phẩm
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (Quy trình/bài báo/đào tạo)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="expectedOutcome"
                      values={values.expectedOutcome}
                      errors={errors.expectedOutcome}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        6. Khả năng ứng dụng vào Trung tâm/khoa phòng/bệnh
                        viện
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="feasibility"
                      values={values.feasibility}
                      errors={errors.feasibility}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        7. Thông tin khác
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (nếu có, đề tài dựa vào chương trình đề tài khác)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="otherInformation"
                      values={values.otherInformation}
                      errors={errors.otherInformation}
                      handleChange={handleChange}
                    />
                  </Row>
                </div>
              </Modal.Body>
              <Modal.Footer className="flex justify-content-end">
                <button
                  type="submit"
                  className="spaces min-w-90px btn btn-primary btn btn-primary"
                >
                  Lưu
                </button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default AddProposalDialog;
