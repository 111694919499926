import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { ACCEPT_FILE_TYPE, CODE } from "../../../utils/Constant";
import { handleDownloadFile } from "../../../utils/ServicesUtils";
import { addReviewAcceptanceCouncil, getTopicById, signScienceAcceptance } from "../services/services";
import { ConfirmDialog } from "../../../component/ConfirmDialog";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import AutocompleteV2 from "../../../component/AutocompleteObjectV2";

export type TAcceptanceForm = {
  topicId?: string;
  topicName?: string;
  topicCode?: string;
  duration?: string;
  topicReviewerId?: string;
  reviewerName?: string;
  reviewerAcademicTitle?: string;
  reviewerPosition?: string;
  reviewerExpertise?: string;
  criterion1?: string;
  criterion2?: string;
  criterion3?: string;
  criterion4?: string;
  criterion5?: string;
  criterion6?: string;
  criterion7?: string
  fileId?: string;
  originalFileName?: string
};
const initAddAcceptanceForm: TAcceptanceForm = {
  topicReviewerId: "",
  reviewerName: "",
  topicName: "",
  topicCode: ""
};
interface Props {
  show: boolean;
  onHide: () => void;
  id?: string;
  onRefresh?: () => Promise<void>;
  dataDetails?: TAcceptanceForm;
  isView?: boolean;
}
const AcceptanceForm = (props: Props) => {
  const { show, onHide, id, onRefresh, isView, dataDetails } = props;
  const { setIsLoading } = useContext(AppContext);

  const [valueAcceptance, setValueAcceptance] = useState(initAddAcceptanceForm);
  const [numRows, setNumRows] = React.useState<any>({});
  const [file, setFile] = useState<any>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [signInfo, setSignInfo] = useState<any>(null);
  const [acceptanceReviewers, setAcceptanceReviewers] = useState<any[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    topicReviewerId: Yup.string().required("Vui lòng chọn ủy viên hội đồng")
  });

  useEffect(() => {
    if (!id) return;
    getTopicDetails(id);
  }, [id]);

  useEffect(() => {
    if (!dataDetails) return;
    setValueAcceptance(dataDetails);
    setSignInfo(dataDetails);
  }, [dataDetails]);

  const getTopicDetails = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(topicId);
      if (data?.code === CODE.SUCCESS) {
        setValueAcceptance({
          ...initAddAcceptanceForm,
          topicName: data?.data?.name,
          topicCode: data?.data?.code,
          duration: `Từ tháng ${data?.data?.startDate ? moment(data?.data?.startDate).format("MM/YYYY") : ""} đến tháng ${data?.data?.endDate ? moment(data?.data?.endDate).format("MM/YYYY") : ""}`,
        });
        setAcceptanceReviewers(data?.data?.acceptanceReviewers || []);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const autoResize = (textarea: any) => {
    const name = textarea.name;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
    setNumRows({
      ...numRows,
      [name]: textarea.scrollHeight / 24,
    });
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValueAcceptance({
      ...valueAcceptance,
      [name]: value,
    });
    autoResize(e.target)
  };

  const handleChangeFile = async (e: any) => {
    let file = e?.target?.files?.[0];
    if (!file) return;
    if (![ACCEPT_FILE_TYPE.DOC, ACCEPT_FILE_TYPE.DOCX, ACCEPT_FILE_TYPE.PDF].includes(file.type)) {
      toast.warning("Chi được phép tải lên tệp tin nên định dạng .doc, .docx hoặc .pdf");
      e.target.value = '';
    } else {
      setFile(file);
    }
  };

  const handleSubmit = async () => {
    let dataReview = {
      ...valueAcceptance,
      topicId: id
    };
    let formData = new FormData();
    if (file && typeof file !== "string") {
      formData.append("file", file);
    };
    Object.entries(dataReview)?.forEach(([key, value]) => {
      formData.append(key, String(value));
    });
    try {
      setIsLoading(true);
      let { data } = await addReviewAcceptanceCouncil(formData);
      if (CODE.SUCCESS === data?.code) {
        setSignInfo(data?.data);
        setIsLoading(false);
        toast.success("Thêm đánh giá thành công");
        setDisabled(true);
        onRefresh?.();
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleYesClick = async () => {
    try {
      let { data } = await signScienceAcceptance(signInfo?.id);
      if (data?.code === CODE.SUCCESS) {
        setSignInfo({
          ...signInfo,
          signed: true
        });
        onRefresh?.();
        setOpenConfirmDialog(false);
        toast.success(`Ký phiếu đánh giá thành công!`);
      } else {
        toast.error(`Ký phiếu đánh giá thất bại`);
        setOpenConfirmDialog(false);
      }
    } catch (error) {
      console.error(error);
      setOpenConfirmDialog(false);
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      className="spaces h-calc-vh-50"
      scrollable
    >
      <Modal.Header className="header-modal min-h-40px" closeButton>
        <Modal.Title className="py-4">Phiếu đánh giá nghiệm thu công trình nghiên cứu KHCN</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          topicReviewerId: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, errors, touched }) => (
          <FormikForm className="form-evaluation">
            <Modal.Body className="a4-page">
              <Row>
                <Col
                  xs={12}
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="mb-4">
                    <h3 className="text-center" style={{ fontWeight: "normal" }}>
                      SỞ Y TẾ HÀ NỘI
                    </h3>
                    <h3
                      className="text-center"
                      style={{ borderBottom: "1px solid black" }}
                    >
                      BỆNH VIỆN ĐA KHOA SÓC SƠN
                    </h3>
                  </div>
                  <div className="mb-4">
                    <h3 className="text-center" style={{ fontWeight: "normal" }}>
                      CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
                    </h3>
                    <h3
                      className="text-center"
                      style={{ borderBottom: "1px solid black" }}
                    >
                      Độc lập- Tự do- Hạnh phúc
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="mb-4">
                    <h3 className="text-center">
                      PHIẾU ĐÁNH GIÁ NGHIỆM THU CÔNG TRÌNH NGHIÊN CỨU KHCN
                    </h3>
                    <h3 className="text-center" style={{ fontWeight: "normal" }}>
                      HỘI ĐỒNG KHCN NGHIỆM THU CẤP CƠ SỞ
                    </h3>
                  </div>
                </Col>
                <Col xs={12}>
                  <div
                    className="mb-4 d-flex"
                    style={{ border: "1px solid black", padding: "10px" }}
                  >
                    <div
                      className="name-topic"
                      style={{ borderRight: "1px solid black", width: "50%" }}
                    >
                      <span style={{ fontWeight: "normal" }}>Tên đề tài:</span>"
                      {valueAcceptance.topicName ?? ""}"
                    </div>
                    <div style={{ width: "50%", paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "normal" }}>Mã số: </span>
                      {valueAcceptance.topicCode ?? ""}
                      <div>
                        Thời gian thực hiện đề tài: {valueAcceptance?.duration ?? ""}
                      </div>
                    </div>

                  </div>
                </Col>
                <Col xs={12}>
                  <div className="d-flex mb-4 justify-content-between align-items-center">
                    <div className="form-title">Họ và tên ủy viên hội đồng: </div>
                    {
                      (isView || disabled) ? (
                        <input
                          style={{ width: "480px" }}
                          type="text"
                          name="reviewerName"
                          readOnly={isView || disabled}
                          className="text-field"
                          value={valueAcceptance.reviewerName}
                        />
                      ) : (
                          <div className="spaces W-480">
                          <AutocompleteV2
                            options={acceptanceReviewers}
                            menuPlacement="bottom"
                            name="topicReviewerId"
                            onChange={(option: any) => {
                              setFieldValue("topicReviewerId", option?.id);
                              setValueAcceptance({
                                ...valueAcceptance,
                                topicReviewerId: option?.id,
                                reviewerName: option?.name
                              });
                            }}
                            getOptionLabel={(e: any) => e?.name}
                            errors={errors?.topicReviewerId}
                            touched={touched?.topicReviewerId}
                            isClearable={false}
                            isDisabled={isView || disabled}
                          />
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-4">
                    <div className="form-title"> Học hàm học vị:</div>
                    <input
                      style={{ width: "545px" }}
                      type="text"
                      readOnly={isView || disabled}
                      className="text-field"
                      name="reviewerAcademicTitle"
                      value={valueAcceptance.reviewerAcademicTitle}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="d-flex mb-4">
                    <div className="form-title"> Chức vụ :</div>
                    <input
                      style={{ width: "584px" }}
                      type="text"
                      readOnly={isView || disabled}
                      className="text-field"
                      name="reviewerPosition"
                      value={valueAcceptance.reviewerPosition}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="d-flex mb-4">
                    <div className="form-title">Chuyên môn :</div>
                    <input
                      style={{ width: "559px" }}
                      type="text"
                      readOnly={isView || disabled}
                      className="text-field"
                      name="reviewerExpertise"
                      value={valueAcceptance.reviewerExpertise}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div>
                    <div className="mb-2 text-center"
                      style={{ fontWeight: "bold" }}
                    >
                      Ý KIẾN NHẬN XÉT ĐÁNH GIÁ
                    </div>
                  </div>
                  <div className="mb-4">
                    <div>
                      <div style={{ fontWeight: 'bold' }}>1.Về các kết quả đạt được so với nội dung nghiên cứu được phê duyệt:</div>
                      <em>(Khối lượng và chất lượng sản phẩm. Giá trị khoa học môi trường, công nghệ, kỹ thuật,...)</em>
                    </div>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        name="criterion1"
                        readOnly={isView || disabled}
                        value={valueAcceptance?.criterion1}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.criterion1)
                          ? 1
                          : numRows?.criterion1,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `${(1 / numRows?.criterion1 || 0) * 100 * index
                              }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div style={{ fontWeight: 'bold' }}>2. Đánh giá chung về việc thực hiện các mục tiêu đặt ra:</div>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        readOnly={isView || disabled}
                        name="criterion2"
                        value={valueAcceptance?.criterion2}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.criterion2)
                          ? 1
                          : numRows?.criterion2,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `${(1 / numRows?.criterion2 || 0) * 100 * index
                              }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div>
                      <div style={{ fontWeight: 'bold' }}>3. Tính hợp lý và khoa học của phương pháp nghiên cứu:</div>
                    </div>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        readOnly={isView || disabled}
                        name="criterion3"
                        value={valueAcceptance?.criterion3}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.criterion3)
                          ? 1
                          : numRows?.criterion3,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `${(1 / numRows?.criterion3 || 0) * 100 * index
                              }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div>
                      <div style={{ fontWeight: 'bold' }}>4. Khả năng ứng dụng vào thực tiễn:</div>
                    </div>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        name="criterion4"
                        readOnly={isView || disabled}
                        value={valueAcceptance?.criterion4}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.criterion4)
                          ? 1
                          : numRows?.criterion4,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `${(1 / numRows?.criterion4 || 0) * 100 * index
                              }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div>
                      <div style={{ fontWeight: 'bold' }}>5. Những nội dung cần được nghiên cứu bổ sung:</div>
                    </div>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        readOnly={isView || disabled}
                        name="criterion5"
                        value={valueAcceptance?.criterion5}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.criterion5)
                          ? 1
                          : numRows?.criterion5,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `${(1 / numRows?.criterion5 || 0) * 100 * index
                              }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div>
                      <div style={{ fontWeight: 'bold' }}>6. Đánh giá<em style={{ fontWeight: 'normal' }}>(Đủ hay không đủ điều kiện):</em></div>
                    </div>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        readOnly={isView || disabled}
                        name="criterion6"
                        value={valueAcceptance?.criterion6}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.criterion6)
                          ? 1
                          : numRows?.criterion6,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `${(1 / numRows?.criterion6 || 0) * 100 * index
                              }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div>
                      <div style={{ fontWeight: 'bold' }}>7. Kiến nghị:</div>
                    </div>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        readOnly={isView || disabled}
                        name="criterion7"
                        value={valueAcceptance?.criterion7}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.criterion7)
                          ? 1
                          : numRows?.criterion7,
                      })?.map((item: any, index: any) => (
                        <span
                          key={item}
                          style={{
                            position: "absolute",
                            top: `${(1 / numRows?.criterion7 || 0) * 100 * index
                              }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}

                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="flex gap-3 w-100 mt-4">
                    {valueAcceptance?.fileId ? (
                      <>
                        <Form.Group controlId="formFile" className="flex-1">
                          <Form.Control
                            type="text"
                            disabled
                            size="sm"
                            value={valueAcceptance?.originalFileName}
                          />
                        </Form.Group>
                        <Button variant="primary" onClick={() => handleDownloadFile(valueAcceptance?.fileId as string, valueAcceptance?.originalFileName as string)}>Tải xuống</Button>
                      </>
                    ) : (
                      <Form.Group controlId="formFile" className="flex-1">
                        <Form.Control
                          disabled={isView || disabled}
                          type="file"
                          name={file?.name}
                          size="sm"
                          accept={".pdf, .doc, .docx"}
                          onChange={(e: any) => handleChangeFile(e)}
                        />
                      </Form.Group>
                    )}
                  </div>
                  <div className="d-flex flex-end mt-8">
                    <div className="spaces width-35 text-center">
                      <p>
                        <b className="text-uppercase">Người đánh giá</b>
                      </p>
                      <p className="pt-3">{signInfo?.signerName ?? ""}</p>
                      <i>{signInfo?.signed ? "(Đã ký)" : ""}</i>
                      {signInfo?.allowSigning && !signInfo?.signed && (
                        <p className="pt-4">
                          <Button
                            variant="primary"
                            className="min-w-75px fw-bold"
                            type="button"
                            onClick={() => {
                              setOpenConfirmDialog(true);
                            }}
                          >
                            {"Ký"}
                          </Button>
                        </p>
                      )}
                    </div>
                  </div>
                  {openConfirmDialog && (
                    <ConfirmDialog
                      show={openConfirmDialog}
                      yes="Xác nhận"
                      onYesClick={() => handleYesClick()}
                      message={`Bạn có chắc muốn ký phiếu đánh giá này?`}
                      close="Huỷ"
                      onCloseClick={() => setOpenConfirmDialog(false)}
                      title="Xác nhận thao tác"
                    />
                  )}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              {!isView && !signInfo?.allowSigning && (
                <Button
                  variant="primary"
                  className="min-w-75px fw-bold"
                  type="submit"
                >
                  {"Lưu"}
                </Button>
              )}
              <Button
                variant="secondary"
                className="min-w-75px fw-bold"
                onClick={onHide}
              >
                Đóng
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AcceptanceForm;
