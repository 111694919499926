import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopicRegistrationContext from "../modules/dang-ky-de-tai/TopicRegistrationContext";
import MyTopicContext from "../modules/de-tai-cua-toi/MyTopicContext";
import MyTopicAttachment from "../modules/de-tai-cua-toi/components/TopicAttachment";
import MyTopicDetails from "../modules/de-tai-cua-toi/components/TopicDetails";
import TopicOutline from "../modules/de-tai-cua-toi/components/TopicOutline";
import MyTopicProduct from "../modules/de-tai-cua-toi/components/TopicProduct";
import TopicContext from "../modules/quan-ly-de-tai/de-tai/TopicContext";
import TopicAttachment from "../modules/quan-ly-de-tai/de-tai/components/TopicAttachment";
import TopicDetails from "../modules/quan-ly-de-tai/de-tai/components/TopicDetails";
import TopicExtend from "../modules/quan-ly-de-tai/de-tai/components/TopicExtend";
import TopicFeedback from "../modules/quan-ly-de-tai/de-tai/components/TopicFeedback";
import TopicProduct from "../modules/quan-ly-de-tai/de-tai/components/TopicProduct";
import TopicRequestEdit from "../modules/quan-ly-de-tai/de-tai/components/TopicRequestEdit";
import TopicResultAnnouncement from "../modules/quan-ly-de-tai/de-tai/components/TopicResultAnnouncement";
import OpenBatchOfRegistrationContext from "../modules/quan-ly-de-tai/mo-dot-dang-ky/OpenBatchOfRegistrationContext";
import ScienceCouncilManagerContext from "../modules/quan-ly-hoi-dong/khoa-hoc/ScienceCouncilManagerContext";
import AcceptanceCouncilManagerContext from "../modules/quan-ly-hoi-dong/nghiem-thu/AcceptanceCouncilManagerContext";
import { HomePage } from "../pages/Homepage/HomePage";
import { MenuTestPage } from "../pages/MenuTestPage";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import TopicRequestEditAcceptanceAccceptance from "../modules/quan-ly-de-tai/de-tai/components/TopicRequestEditAccceptance";
import AssignmentImplementProvider from "../modules/giao-thuc-hien/AssignmentImplementContext";
import TopicRecognitionProvider from "../modules/cong-nhan-de-tai/TopicRecognitionContext";
const PrivateRoutes = () => {
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));

  return (

    <Routes>
      <Route index element={<Navigate to="/home" />} />
      <Route path="/*" element={<HomePage />} />
      <Route path="home" element={<HomePage />} />
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/home" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
          <Route path="quan-ly-de-tai/de-tai" element={<TopicContext />} />
          <Route path="quan-ly-de-tai/de-tai/:id" element={<TopicDetails />} />
          <Route path="quan-ly-de-tai/de-tai/:id/san-pham" element={<TopicProduct />} />
          <Route path="quan-ly-de-tai/de-tai/:id/giay-to-lien-quan" element={<TopicAttachment />} />
          <Route path="quan-ly-de-tai/de-tai/:id/thong-bao-ket-qua" element={<TopicResultAnnouncement />} />
          <Route path="quan-ly-de-tai/de-tai/:id/gop-y" element={<TopicFeedback />} />
          <Route path="quan-ly-de-tai/de-tai/:id/yeu-cau-sua" element={<TopicRequestEdit />} />
          <Route path="quan-ly-de-tai/de-tai/:id/yeu-cau-sua-nghiem-thu" element={<TopicRequestEditAcceptanceAccceptance />} />
          <Route path="quan-ly-de-tai/de-tai/:id/gia-han" element={<TopicExtend />} />
          <Route path="quan-ly-de-tai/mo-dot-dang-ky" element={<OpenBatchOfRegistrationContext />} />
          <Route path="dang-ky-de-tai" element={<TopicRegistrationContext />} />
          <Route path="de-tai-cua-toi" element={<MyTopicContext />} />
          <Route path="de-tai-cua-toi/:id" element={<MyTopicDetails />} />
          <Route path="de-tai-cua-toi/:id/de-cuong" element={<TopicOutline />} />
          <Route path="de-tai-cua-toi/:id/nop-bao-cao" element={<MyTopicProduct />} />
          <Route path="de-tai-cua-toi/:id/giay-to-khac" element={<MyTopicAttachment />} />
          <Route path="quan-ly-hoi-dong/khoa-hoc" element={<ScienceCouncilManagerContext />} />
          <Route path="quan-ly-hoi-dong/nghiem-thu" element={<AcceptanceCouncilManagerContext />} />
          <Route path="quyet-dinh-giao-thuc-hien" element={<AssignmentImplementProvider />} />
          <Route path="quyet-dinh-cong-nhan" element={<TopicRecognitionProvider />} />
        {/* Lazy Modules */}
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>

  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

