import { FC, useContext, useEffect, useState } from "react";
import EvaluationForm, { TEvaluationForm } from "../../../quan-ly-de-tai/de-tai/components/EvaluationForm";
import { CODE } from "../../../utils/Constant";
import { AppContext } from "../../../appContext/AppContext";
import { toast } from "react-toastify";
import { getReviewScienceList } from "../../services/services";

type Iprops = {
  topicId?: string;
  councilId?: string;
  topicInfo?: any
  allowEdit?: boolean
};
const TabReviewScienceCouncil: FC<Iprops> = (props) => {

  const { topicId, councilId, topicInfo, allowEdit } = props;
  const { setIsLoading } = useContext(AppContext);

  const [isOpenReviewDialog, setIsOpenReviewDialog] = useState<boolean>(false);
  const [reviews, setReviews] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<TEvaluationForm>();
  const [isView, setIsView] = useState<boolean>(false);

  useEffect(() => {
    if (!topicId) return;
    getListReview(topicId);
  }, [topicId]);

  const getListReview = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getReviewScienceList(topicId);
      if (data?.code === CODE.SUCCESS) {
        setReviews(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="form spaces p-10">
      <table className="comment-review">
        <thead>
          <tr>
            <td className="fw-bold" width={50}>STT</td>
            <td className="fw-bold" width={200}>Người đánh giá</td>
            <td className="fw-bold" width={150}>Tổng số điểm</td>
            <td className="fw-bold">Ý kiến nhận xét & đánh giá</td>
            <td className="fw-bold" width={150}>Thao tác</td>
          </tr>
        </thead>
        <tbody>
          {reviews?.map((item, index) => (
            <tr key={index + ""}>
              <td className="text-center">{index + 1}</td>
              <td>{item?.reviewerName}</td>
              <td className="text-center">{item?.sum}</td>
              <td>{item?.opinion}</td>
              <td className="text-center">
                <span
                  className="tooltips"
                  data-tooltip="Xem chi tiết"
                  data-tooltip-pos="left"
                  data-tooltip-length="medium"
                >
                  <i className="bi bi-eye-fill m-0 fs-1 cursor-pointer text-danger" onClick={() => {
                    setIsView(true);
                    setSelectedRow(item);
                    setIsOpenReviewDialog(true);
                  }}></i>
                </span>
               {allowEdit && (
                  <span
                    className="tooltip ms-4"
                    data-tooltip="Chỉnh sửa"
                    data-tooltip-pos="left"
                    data-tooltip-length="medium"
                  >
                    <i className="bi bi-pencil-fill m-0 fs-2 cursor-pointer text-pri" onClick={() => {
                      setIsView(false);
                      setSelectedRow(item);
                      setIsOpenReviewDialog(true);
                    }}></i>
                  </span>
               )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isOpenReviewDialog && (
        <EvaluationForm
          show={isOpenReviewDialog}
          onHide={() => setIsOpenReviewDialog(false)}
          onRefresh={() => getListReview(topicId!)}
          dataDetails={{ ...selectedRow ?? {}, members: topicInfo?.members } as TEvaluationForm}
          isView={isView}
          councilId={councilId}
        />
      )}
    </div>
  );
};

export default TabReviewScienceCouncil;
