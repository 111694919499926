import { FC, useContext, useEffect, useState } from "react";
import { Accordion, Button, Form, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { getAttachmentList } from "../../../de-tai-cua-toi/services/services";
import { ACCEPT_FILE_TYPE, CODE, RELATED_PAPER } from "../../../utils/Constant";
import { handleDownloadFile, handleDownloadSampleFile } from "../../../utils/ServicesUtils";
import { getListAttachment, REPORT_TYPE } from "../constants/constants";
import { TTopic } from "../models/TopicModel";
import { addReportAttachment } from "../services/services";

const TopicAttachment: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const state = location.state  as { show?: boolean, data?: TTopic };

  const [files, setFiles] = useState<any>({});

  const handleChangeFile = async (e: any) => {
    let file = e?.target?.files?.[0];
    let name = e?.target?.name;
    if(!file) return;
    if(![ACCEPT_FILE_TYPE.DOC, ACCEPT_FILE_TYPE.DOCX, ACCEPT_FILE_TYPE.PDF].includes(file.type)) {
      toast.warning("Chi được phép tải lên tệp tin nên định dạng .doc, .docx hoặc .pdf");
      e.target.value = '';
    }else{
      setFiles({
        ...files,
        [name]: file
      })
    }
  };

  useEffect(() => {
    getFiles();
  }, [])

  const getFiles = async () => {
    let topicId = state?.data?.id;
    if (!topicId) return;
    try {
      setIsLoading(true);
      let { data } = await getAttachmentList({ topicId, typeId: String(REPORT_TYPE.ATTACHMENT) });
      if (data?.code === CODE.SUCCESS) {
        let files = getListAttachment(data?.data);
        setFiles(files);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const handleSendFile = async (name: string, subTypeId: number) => {
    let topicId = state.data?.id;
    if(!topicId) return;
    if(!files[name]) {
      toast.warning("Chưa chọn tệp tin");
      return;
    }
    let formData = new FormData();
    formData.append('topicId', topicId);
    formData.append('typeId', String(REPORT_TYPE.ATTACHMENT));
    formData.append('file', files[name]);
    formData.append('subTypeId', String(subTypeId));

    try {
      setIsLoading(true);
      const { data } = await addReportAttachment(formData);
      if(data?.code === CODE.SUCCESS) {
        toast.success("Tải tệp tin thành công");
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      centered
      show={state?.show}
      onHide={() => navigate("/quan-ly-de-tai/de-tai", { replace: true })}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Modal.Header className="header-modal min-h-40px">
        <div className="d-flex gap-6 py-2">
          <div
            className="d-flex align-items-center title-back gap-3 cursor-pointer"
            onClick={() => {
              navigate("/quan-ly-de-tai/de-tai", { replace: true });
            }}
          >
            <i className="spaces bi bi-chevron-left ml-10"> </i>
            <div className="spaces mr-20">Quay lại</div>
          </div>
          <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
            Giấy tờ liên quan
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body className="spaces p-0 mt-3 bg-white">
        <Accordion defaultActiveKey="0">
          {RELATED_PAPER.map((item, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>{item.title}</Accordion.Header>
              <Accordion.Body>
                <div className="flex gap-3 w-100">
                  {files?.[item.subType] ? (
                    <>
                      <Form.Group controlId="formFile" className="flex-1">
                        <Form.Control
                          type="text"
                          name={item.name}
                          disabled
                          size="sm"
                          value={files?.[item.subType]?.originalName}
                        />
                      </Form.Group>
                      {item?.hasSample && (
                        <Button variant="primary" onClick={() => handleDownloadSampleFile(`[Mẫu]${item.title}.doc`, item.apiPath || "")}>Tải mẫu</Button>
                      )}
                      <Button variant="primary" onClick={() => handleDownloadFile(files?.[item.subType]?.id, files?.[item.subType]?.originalName)}>Tải xuống</Button>
                    </>
                  ): (
                    <>
                      <Form.Group controlId="formFile" className="flex-1">
                        <Form.Control
                          type="file"
                          name={item.name}
                          size="sm"
                          accept={".pdf, .doc, .docx"}
                          onChange={(e: any) => handleChangeFile(e)}
                        />
                      </Form.Group>
                      {item?.hasSample && (
                        <Button variant="primary" onClick={() => handleDownloadSampleFile(`[Mẫu]${item.title}.doc`, item.apiPath || "")}>Tải mẫu</Button>
                      )}
                      <Button variant="primary" onClick={() => handleSendFile(item.name, item.subType)}>Gửi file</Button>
                    </>
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};
export default TopicAttachment;
