import { Form, Formik, FormikValues } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { StepperComponent } from "../../../../../_metronic/assets/ts/components";
import { AppContext } from "../../../appContext/AppContext";
import { ACCEPT_FILE_TYPE, CODE } from "../../../utils/Constant";
import { getAcceptanceCouncilById } from "../../nghiem-thu/services/services";
import {
  initAddScienceCouncil,
  TAddScienceCouncil,
  TScienceCouncilDetails,
  TScienceCouncilMember,
  TScienceCouncilTopic,
} from "../models/ScienceCouncilManagerModels";
import { addScienceCouncil, updateScienceCouncil } from "../services/services";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import moment from "moment";

interface Props {
  show: boolean;
  onHide: () => void;
  onRefresh: () => void;
  id?: string;
}

const AddCouncilDialog = (props: Props) => {
  const { show, onHide, onRefresh, id } = props;
  const { setIsLoading } = useContext(AppContext);

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const formRef = useRef<any>(null);

  const [initValues] = useState<TAddScienceCouncil>(initAddScienceCouncil);
  const [isSubmitButton, setIsSubmitButton] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState<TScienceCouncilTopic[]>([]);
  const [file, setFile] = useState<any>();
  const [regisPeriodId, setRegisPeriodId] = useState<string>("");
  const [details, setDetails] = useState<TScienceCouncilDetails>({
    members: [],
    topics: [],
  });

  useEffect(() => {
    if (!id) return;
    getDetails(id);
  }, [id]);
  useEffect(() => {
    if (details?.id) {
      setFile(details?.originalFileName);
      formRef.current?.setFieldValue("file", details?.originalFileName);
    } else {
      setFile(null);
    }
  }, [details]);
  const createScienceCouncilSchemas = [
    Yup.object({
      regisPeriodId: Yup.string().required("Vui lòng nhập thông tin!"),
      date: Yup.string().nullable()
        .test(
          "after-start-date",
          "Ngày diễn ra phải sau đợt đăng ký",
          (value: any, { parent }) => {
            if (id) {
              return true;
            } else {
              const endDate = moment(value);
              const startDate = moment(parent?.regisPeriod);
              return endDate.isSameOrAfter(startDate);
            }
          }
        ),
      file: Yup.mixed().nullable()
        .when("$file", {
          is: () => !file, 
          then: (schema) => schema.required("Vui lòng chọn file!"),
        }),
    }),
    Yup.object({}),
    Yup.object({}),
  ];
  const [currentSchema, setCurrentSchema] = useState(
    createScienceCouncilSchemas[0]
  );
  const getDetails = async (id: string) => {
    try {
      setIsLoading(true);
      let { data } = await getAcceptanceCouncilById(id);
      if (data?.code === CODE.SUCCESS) {
        setDetails(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
    setIsSubmitButton(
      stepper.current.currentStepIndex === stepper.current.totatStepsNumber
    );

    setCurrentSchema(
      createScienceCouncilSchemas[stepper.current.currentStepIndex - 1]
    );
  };

  const submitStep = (values: TAddScienceCouncil, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }

    if (values?.regisPeriodId) {
      setRegisPeriodId(values?.regisPeriodId);
    }

    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber) {
      handleSubmit(values);
    }

    setCurrentSchema(
      createScienceCouncilSchemas[stepper.current.currentStepIndex]
    );

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      setIsSubmitButton(
        stepper.current.currentStepIndex ===
          stepper.current.totatStepsNumber - 1
      );
      stepper.current.goNext();
    } else {
      actions.resetForm();
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  const handleChangeFile = (e: any) => {
    let file = e?.target?.files?.[0];
    if (
      ![
        ACCEPT_FILE_TYPE.DOC,
        ACCEPT_FILE_TYPE.DOCX,
        ACCEPT_FILE_TYPE.PDF,
      ].includes(file?.type)
    ) {
      toast.warning(
        "Chi được phép tải lên tệp tin nên định dạng .doc, .docx hoặc .pdf"
      );
      e.target.value = "";
    } else {
      setFile(file);
      formRef.current?.setFieldValue("file", file);
    }
  };

  const handleSubmit = async (values: any) => {
    let members = values?.members?.map((member: any) => {
      return {
        name: member?.name,
        gender: member?.gender,
        duty: member?.duty,
        academicRank: member?.academicRank,
        degree: member?.degree,
        department: member?.department,
        position: member?.position,
        email: member?.email,
        phone: member?.phone,
        office: member?.office,
        username: member?.username,
        isChairman: member?.isChairman,
        isSecretary: member?.isSecretary
      }
    })

    let newValues = {
      ...values,
      year: Number(values.year),
      members: members,
      topics: selectedTopics.map((item: TScienceCouncilTopic) => {
        return {
          id: item.id,
        };
      }),
    };
    let formData = new FormData();

    formData.append("year", String(newValues?.year ?? ""));
    formData.append("code", String(newValues?.code ?? ""));
    formData.append("regisPeriodId", String(newValues?.regisPeriodId ?? ""));
    formData.append("name", String(newValues?.name ?? ""));
    formData.append("time", String(newValues?.time ?? ""));
    formData.append("date", String(newValues?.date ?? ""));
    formData.append("place", String(newValues?.place ?? ""));
    formData.append("typeId", String(newValues?.typeId ?? ""));
    if (file && typeof file !== "string") {
      formData.append("file", file);
    }
    formData.append("numMembers", String(newValues?.numMembers ?? ""));

    if (newValues?.members && newValues?.members?.length > 0) {
      newValues?.members.forEach((member: TScienceCouncilMember, index: any) => {
        formData.append(`members[${index}].name`, String(member?.name ?? ""));
        formData.append(`members[${index}].gender`, String((member?.gender === 0 ? "0" : member?.gender) ?? ""));
        formData.append(`members[${index}].position`, String(member?.position ?? ""));
        formData.append(`members[${index}].duty`, String(member?.duty ?? ""));
        formData.append(
          `members[${index}].academicRank`,
          String(member?.academicRank ?? "")
        );
        formData.append(`members[${index}].degree`, String(member?.degree ?? ""));
        formData.append(`members[${index}].username`, String(member?.username ?? ""));
        formData.append(
          `members[${index}].department`,
          String(member?.department ?? "")
        );
        formData.append(`members[${index}].email`, String(member?.email ?? ""));
        formData.append(`members[${index}].phone`, String(member?.phone ?? ""));
        formData.append(`members[${index}].office`, String(member?.office ?? ""));
        formData.append(`members[${index}].isChairman`, String(member?.isChairman || "false"));
        formData.append(`members[${index}].isSecretary`, String(member?.isSecretary || "false"));
      });
    }

    if (newValues?.topics && newValues?.topics?.length > 0) {
      newValues?.topics.forEach((topic: TScienceCouncilTopic, index: number) => {
        formData.append(`topics[${index}].id`, String(topic.id));
      });
    }
    try {
      setIsLoading(true);
      let { data } = await (id
        ? updateScienceCouncil(formData, id)
        : addScienceCouncil(formData));
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success(
          `${id ? "Cập nhật" : "Thêm mới"} hội đồng khoa học thành công`
        );
        onHide();
        onRefresh();
      } else {
        setIsLoading(false);
        onHide();
        let errorMesage =
          data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      onHide();
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitStep = () => {
    formRef.current?.handleSubmit();
  };
  
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      className="spaces h-calc-vh-50"
      scrollable
    >
      <div
        ref={stepperRef}
        className="stepper stepper-links d-flex flex-column"
        id="kt_create_account_stepper"
      >
        <Modal.Header className="header-modal min-h-40px" closeButton>
          <Modal.Title className="py-4">{`${
            id ? "Cập nhật" : "Thêm"
          } hội đồng khoa học`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="spaces p-0 bg-white mxh-65vh science-council">
          <div className="card">
            <div className="card-body p-0">
              <div
                ref={stepperRef}
                className="stepper stepper-links d-flex flex-column"
                id="kt_create_account_stepper"
              >
                <div className="stepper-nav">
                  <div
                    className="stepper-item current"
                    data-kt-stepper-element="nav"
                  >
                    <h3 className="stepper-title">1. Thêm thông tin chung</h3>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <h3 className="stepper-title">
                      2. Thêm thành viên hội đồng
                    </h3>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <h3 className="stepper-title">3. Chọn đề tài</h3>
                  </div>
                </div>

                <Formik<TAddScienceCouncil>
                  innerRef={formRef}
                  enableReinitialize
                  validationSchema={currentSchema}
                  initialValues={
                    details?.regisPeriodId && details?.id
                      ? {
                          ...initValues,
                          members: details?.members || [],
                          topics: details?.topics || [],
                          numMembers: details?.numMembers,
                          numTopics: details?.numTopics,
                          ...details,
                        }
                      : initValues
                  }
                  onSubmit={submitStep}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form
                      className="mx-auto px-10 w-100 py-4"
                      id="kt_create_council-form"
                    >
                      <div
                        className="current"
                        data-kt-stepper-element="content"
                      >
                        <Step1
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          handleChangeFile={handleChangeFile}
                          details={details}
                        />
                      </div>

                      <div data-kt-stepper-element="content">
                        <Step2
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          details={details}
                          handleBlur={handleBlur}
                        />
                      </div>

                      <div data-kt-stepper-element="content">
                        <Step3
                          setSelectedTopics={setSelectedTopics}
                          regisPeriodId={regisPeriodId}
                          details={details}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-stack w-100">
            <div className="mr-2">
              <button
                onClick={prevStep}
                type="button"
                className="btn btn-primary me-3"
                data-kt-stepper-action="previous"
              >
                <i className="bi bi-chevron-bar-left m-0"></i>{""}
                Quay lại
              </button>
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                onClick={handleSubmitStep}
              >
                <span className="indicator-label">
                  {!isSubmitButton && (
                    <>
                      <span>Tiếp theo </span>
                      <i className="bi bi-chevron-bar-right m-0"></i>
                    </>
                  )}
                  {isSubmitButton && (
                    <>
                      <span>Xác nhận </span>
                      <i className="bi bi-send m-0"></i>
                    </>
                  )}
                </span>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default AddCouncilDialog;
