import { createContext, Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import AssignmentImplement from "./AssignmentImplement";
import "./AssignmentImplement.scss";
import { initFilter } from "./models/AssignmentImplementModel";

export interface AssignmentImplementContextProps {
  assignmentImplementList: any;
  setAssignmentImplementList: Dispatch<SetStateAction<any>>;
  onReset: () => void;
  proposalInfo: any;
  setProposalInfo: Dispatch<SetStateAction<any>>;
  onResetProposal: () => void;
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
}

const initialContext = {
  assignmentImplementList: {},
  setAssignmentImplementList: () => {},
  onReset: () => {},
  proposalInfo: {},
  setProposalInfo: () => {},
  onResetProposal: () => {},
  filter: {},
  setFilter: () => {},
};

export const AssignmentImplementContext =
  createContext<AssignmentImplementContextProps>(initialContext);

export const AssignmentImplementProvider: FC = () => {
  const [assignmentImplementList, setAssignmentImplementList] = useState<any>({});
  const [proposalInfo, setProposalInfo] = useState<any>({});
  const [filter, setFilter] = useState<any>(initFilter);
  const value = useMemo(
    () => ({
      assignmentImplementList,
      setAssignmentImplementList,
      onReset: () => {
        setAssignmentImplementList({});
      },
      proposalInfo,
      setProposalInfo,
      filter,
      setFilter,
      onResetProposal: () => {
        setAssignmentImplementList({});
      },
    }), [assignmentImplementList, proposalInfo, filter]);
  return (
    <AssignmentImplementContext.Provider
      value={value}
    >
      <div className="bg-white h-100">
        <div className="flex-1 flex flex-column">
          <div className="py-1">
            <AssignmentImplement />
          </div>
        </div>
      </div>
    </AssignmentImplementContext.Provider>
  );
};

export default AssignmentImplementProvider;
